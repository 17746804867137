import Colors from "../../constants/colors";

const EyeClose = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.507"
      height="27"
      viewBox="0 0 31.507 27"
      fill={color === Colors.black ? Colors.black : color}
      {...props}
    >
      <path
        id="Icon_ionic-md-eye-off"
        data-name="Icon ionic-md-eye-off"
        d="M18.007,10.181a7.142,7.142,0,0,1,7.165,7.109,6.838,6.838,0,0,1-.513,2.6l4.184,4.148a16.809,16.809,0,0,0,4.915-6.75A16.968,16.968,0,0,0,12.291,7.622l3.094,3.073A7.084,7.084,0,0,1,18.007,10.181ZM3.684,6.307l3.27,3.241.661.654A16.729,16.729,0,0,0,2.25,17.29a16.993,16.993,0,0,0,22.029,9.464l.6.6,4.2,4.148L30.9,29.693,5.5,4.5ZM11.6,14.161l2.222,2.2a4.028,4.028,0,0,0-.113.921,4.275,4.275,0,0,0,4.3,4.261,4.041,4.041,0,0,0,.928-.112l2.222,2.2a7.127,7.127,0,0,1-10.315-6.356A7.023,7.023,0,0,1,11.6,14.161Zm6.173-1.1,4.514,4.479.028-.225a4.275,4.275,0,0,0-4.3-4.261Z"
        transform="translate(-2.25 -4.5)"
      />
    </svg>
  );
};

export default EyeClose;
