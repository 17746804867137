export const homeApiEndpoint = {
  selectorAllTheme: "/api/v1/selector/theme",
  selectorAllProducts: "/api/v1/selector/products",
  selectorAllBrands: "/api/v1/selector/brands/getAll",
  selectorAllCategory: '/api/v1/selector/category',
  selectorAddProduct: '/api/v1/selector/theme/add-product',
  selectorAllFabric: '/api/v1/selector/fabric/getAll',
  selectorAllColor: '/api/v1/selector/colors/getAll',
};

export default homeApiEndpoint;
