import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Text from "../../components/text";
import Dropdown from "../../components/dropdown";
import Table from "./table";
import Chart from "./chart";
import teamApi from "../../apis/teamApi";

import { setLoader } from "../../store/reducer/ui";

import "./styles.scss";
import "../../constants/styles.scss";

const TeamPerformance = () => {
  const authData = useSelector((state) => state.auth);
  const dropDownOption = [
    "All Time",
    "Today",
    "Last 7 Days",
    "Last 30 Days",
    "Last 90 Days",
    "Last 6 Month",
    "Last 12 Month",
  ];

  const dispatch = useDispatch();

  const [teamData, setTeamData] = useState({});
  const [dropDownSelectedItem, setDropDownSelectedItem] = useState(
    dropDownOption[0]
  );
  const [orderSelectionArray, setOrderSelectionArray] = useState([]);

  const listData = () => {
    dispatch(setLoader(true));
    let startDate, endDate;
    if (dropDownSelectedItem === "All Time") {
      startDate = "";
      endDate = "";
    }
    if (dropDownSelectedItem === "Today") {
      startDate = moment().format("MM/DD/YYYY");
      endDate = moment().format("MM/DD/YYYY");
    }
    if (dropDownSelectedItem === "Last 7 Days") {
      startDate = moment().format("MM/DD/YYYY");
      endDate = moment().subtract(7, "days").format("MM/DD/YYYY");
    }
    if (dropDownSelectedItem === "Last 30 Days") {
      startDate = moment().format("MM/DD/YYYY");
      endDate = moment().subtract(30, "days").format("MM/DD/YYYY");
    }
    if (dropDownSelectedItem === "Last 90 Days") {
      startDate = moment().format("MM/DD/YYYY");
      endDate = moment().subtract(90, "days").format("MM/DD/YYYY");
    }
    if (dropDownSelectedItem === "Last 6 Month") {
      startDate = moment().format("MM/DD/YYYY");
      endDate = moment().subtract(6, "months").format("MM/DD/YYYY");
    }
    if (dropDownSelectedItem === "Last 12 Month") {
      startDate = moment().format("MM/DD/YYYY");
      endDate = moment().subtract(12, "months").format("MM/DD/YYYY");
    }
    const payload = {
      startDate,
      endDate,
    };
    teamApi.teamListAll(payload).then((res) => {
      const data = res.data.data;
      setTeamData(data);
      let orderSelection = [];
      for (let i = 0; i < data.length; i++) {
        orderSelection.push(data[i].order_selection);
      }
      setOrderSelectionArray(orderSelection);
    });
    dispatch(setLoader(false));
  };

  useEffect(() => {
    listData();
  }, [dropDownSelectedItem]);

  const onClickMenuItem = (item) => {
    setDropDownSelectedItem(item);
  };

  return (
    <div className="container-fluid dashboard-pages-reusable-style">
      {authData.role === "head_selector" ? (
        <>
          <div className="team-performance-title-container-style">
            <Text
              className="dashboard-pages-reusable-title-style"
              value="Team Performance"
            />
            <Dropdown
              selected={dropDownSelectedItem}
              options={dropDownOption}
              onClickMenuItem={(item) => onClickMenuItem(item)}
            />
          </div>

          <Table list={teamData} />
          <Chart list={teamData} orderSelectionArray={orderSelectionArray} />
        </>
      ) : (
        <Text
          className="fs-16 fw-bold fc-nero"
          value="Team Performance data is only accessible to Head Selector only."
        />
      )}
    </div>
  );
};

export default TeamPerformance;
