import Colors from "../../constants/colors";

const PerformanceIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color === Colors.black ? Colors.black : color}
      {...props}
    >
      <g
        id="Group_49330"
        data-name="Group 49330"
        transform="translate(-32 -86)"
      >
        <rect
          id="Rectangle_17848"
          data-name="Rectangle 17848"
          width="24"
          height="24"
          transform="translate(32 86)"
          fill="none"
        />
        <path
          id="noun-performance-3845073"
          d="M161.221,92.419v5.8h0a.758.758,0,1,1-1.517,0v-5.8a.758.758,0,1,1,1.517,0Zm-6.067.758a.759.759,0,0,0-.758.758v4.286h0a.758.758,0,1,0,1.517,0V93.935a.758.758,0,0,0-.758-.758Zm10.617-3.792a.759.759,0,0,0-.758.758v8.078a.758.758,0,1,0,1.517,0V90.144a.758.758,0,0,0-.758-.758Zm2.533-6.892h0a.758.758,0,0,0-.829-.688l-2.982.278a.759.759,0,1,0,.14,1.511l1.163-.109a18.74,18.74,0,0,1-13.678,5.567h0a.758.758,0,1,0,0,1.517,19.957,19.957,0,0,0,14.835-6.1l.105,1.147a.758.758,0,0,0,.758.692h.068a.759.759,0,0,0,.688-.822Z"
          transform="translate(-115.859 7.698)"
          stroke="#000"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};

export default PerformanceIcon;
