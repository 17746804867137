import { useSelector } from "react-redux";
import DashboardRoutes from "../../routes/dashboard";
import SideBar from "./sideBar";
import Header from "./header";
import "./styles.scss";
import "../../constants/styles.scss";
import {   useNavigate } from "react-router-dom";

const Dashboard = () => {
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const onClickHomeArrow = () => {
    navigate("/")
  }
  return (
    <div className="container-fluid dashboard-main-container">
      <Header userName={authData?.firstName} onClickHomeArrow={onClickHomeArrow}/>
      <div className="dashboard-main-inside-container">
        <SideBar userData={authData}/>
        <DashboardRoutes />
      </div>
    </div>
  );
};

export default Dashboard;
