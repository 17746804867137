import "./styles.scss";
import "../../constants/styles.scss";
import Text from "../../components/text";
import ItemBox from "./itemBox";
import Chart from "./chart";
import ospApi from "../../apis/ospApi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const OrderSelectionPercentage = () => {
  const authData = useSelector((state) => state.auth);
  const dropDownOption = [
    "All Time",
    "Today",
    "Last 7 Days",
    "Last 30 Days",
    "Last 90 Days",
    "Last 6 Month",
    "Last 12 Month",
  ];
  const [items, setItems] = useState({});
  const [chartData, setChartData] = useState({});
  const [dropDownSelectedItem, setDropDownSelectedItem] = useState(
    dropDownOption[0]
  );

  const [calenderStartDate, setCalenderStartDate] = useState("");

  const listData = () => {
    ospApi
      .ospAll()
      .then((res) => {
        setItems(res.data.data);
      })
      .catch((err) => {});
  };

  const performanceByDate = () => {
    let startDate, endDate;
    if (calenderStartDate !== "") {
      const date = calenderStartDate.split(" - ");
      startDate = moment(date[0], "DD/MM/YYYY").format("MM/DD/YYYY");
      endDate = moment(date[1], "DD/MM/YYYY").format("MM/DD/YYYY");
    } else {
      if (dropDownSelectedItem === "All Time") {
        startDate = moment(authData.createdAt, "YYYY-MM-DD").format(
          "MM/DD/YYYY"
        );
        endDate = moment().format("MM/DD/YYYY");
      }
      if (dropDownSelectedItem === "Today") {
        startDate = moment().format("MM/DD/YYYY");
        endDate = moment().format("MM/DD/YYYY");
      }
      if (dropDownSelectedItem === "Last 7 Days") {
        startDate = moment().subtract(7, "days").format("MM/DD/YYYY");
        endDate = moment().format("MM/DD/YYYY");
      }
      if (dropDownSelectedItem === "Last 30 Days") {
        startDate = moment().subtract(30, "days").format("MM/DD/YYYY");
        endDate = moment().format("MM/DD/YYYY");
      }
      if (dropDownSelectedItem === "Last 90 Days") {
        startDate = moment().subtract(90, "days").format("MM/DD/YYYY");
        endDate = moment().format("MM/DD/YYYY");
      }
      if (dropDownSelectedItem === "Last 6 Month") {
        startDate = moment().subtract(6, "months").format("MM/DD/YYYY");
        endDate = moment().format("MM/DD/YYYY");
      }
      if (dropDownSelectedItem === "Last 12 Month") {
        startDate = moment().subtract(12, "months").format("MM/DD/YYYY");
        endDate = moment().format("MM/DD/YYYY");
      }
    }
    const payloadData = {
      startDate,
      endDate,
    };

    ospApi
      .performanceByDate(payloadData)
      .then((res) => {
        if (res.status === 200 && res.data.status === 1) {
          setChartData(res.data.data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    listData();
  }, []);

  useEffect(() => {
    performanceByDate();
  }, [dropDownSelectedItem, calenderStartDate]);

  const onClickMenuItem = (item) => {
    setCalenderStartDate("");
    setDropDownSelectedItem(item);
  };

  const setStartEndDate = (date) => {
    let startDate = moment(date[0]).format("DD/MM/YYYY");
    let endDate = moment(date[1]).format("DD/MM/YYYY");
    setCalenderStartDate(startDate + " - " + endDate);
  };

  return (
    <div className="container-fluid dashboard-pages-reusable-style">
      <Text
        className="dashboard-pages-reusable-title-style"
        value="Order Selection Percentage"
      />
      <div className="order-selection-percentage-horizontal-list-style">
        <ItemBox list={items} />
      </div>
      <Chart
        orderSelectionArray={chartData}
        dropDownSelectedItem={dropDownSelectedItem}
        dropDownOption={dropDownOption}
        onClickMenuItem={(item) => onClickMenuItem(item)}
        calenderValue={calenderStartDate}
        onChangeCalenderValue={(date) => {
          setStartEndDate(date);
        }}
        calenderMinDate={moment(authData.createdAt, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        )}
        calenderMaxDate={moment().format("YYYY-MM-DD")}
      />
    </div>
  );
};

export default OrderSelectionPercentage;
