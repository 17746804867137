import Button from "../../../components/button";
import Text from "../../../components/text";
import "../../../constants/styles.scss";
import "./styles.scss";
import { endPoint } from "../../../apis/api";

const ItemBox = ({list}) => {
  return (
    <>
    {list.length > 0 && list.filter(x => x.themeData).map((item,index) => (
      <div key={index} className="osp-item-box-main-container">
      <div className="osp-item-box-image-container">
        <img
          src={endPoint+"/"+item.themeData.banner_image}
          alt="Girl in a jacket"
          width="83px"
          height="111px"
        />
      </div>
      <Text className="osp-item-box-primary-button-style" value={"Select " + item.product_selection}/>
      <Text className="osp-item-box-secondary-button-style" value={"Order " + item.order_selection} />
      <Text className="fs-12 fc-nero" value={item.themeData.title} />
    </div>
    ))}
    </>
    
  );
};

export default ItemBox;
