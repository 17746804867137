import Button from "../../../components/button";
import Text from "../../../components/text";
import Checkbox from "../../../components/checkbox";
import { useEffect, useState } from "react";
import "../../../constants/styles.scss";
import "./styles.scss";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const Color = (props) => {
  const [color, setColor] = useState([]);
  useEffect(() => {
    console.log(props.colorArray);
  }, [props.colorArray]);

  return (
    <div className="">
      {/* Color */}
      <div className="selector-home-product-filter-boxes-container">
        <Container>
          <Row>
            <Col xs={12} md={12} className="main-color">
              <Row>
                <div className="selector-home-product-filter-heading-container">
                  <Text className="fs-14 fw-b fc-nero" value="Colors" />
                </div>
                <div className="row" style={{display: "contents"}}>
                  {props.colorArray.map((color, index) => (
                    <div
                      key={index}
                      className={`color-container ${
                        color.checked ? "color-filter-selected" : ""
                      }`}
                      onClick={() => props.onClickCheckBox(color, "color")}
                      style={{ "background-color": color.hex_code, margin: "10px" }}
                      title={color.name}
                    ></div>
                  ))}
                  <br />
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Color;
