import Colors from "../../constants/colors";

const LogoutIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color === Colors.black ? Colors.black : color}
      {...props}
    >
      <g
        id="Group_49328"
        data-name="Group 49328"
        transform="translate(-54 -251)"
      >
        <g
          id="Group_48778"
          data-name="Group 48778"
          transform="translate(-23471.352 -1717.95)"
        >
          <path
            id="Path_112620"
            data-name="Path 112620"
            d="M148.354,80.494a.643.643,0,0,0-.643.643V82.85a1.285,1.285,0,0,1-1.285,1.285H142.57a1.285,1.285,0,0,1-1.285-1.285V72.568a1.285,1.285,0,0,1,1.285-1.285h3.856a1.285,1.285,0,0,1,1.285,1.285v1.714a.643.643,0,0,0,1.285,0V72.568a2.571,2.571,0,0,0-2.57-2.57H142.57a2.571,2.571,0,0,0-2.57,2.57V82.85a2.571,2.571,0,0,0,2.57,2.57h3.856A2.571,2.571,0,0,0,149,82.85V81.136a.643.643,0,0,0-.643-.643Z"
            transform="translate(23389.641 1904.002)"
          />
          <path
            id="Path_112621"
            data-name="Path 112621"
            d="M244.76,167.449l-3.787-3.787a.644.644,0,1,0-.908.913l2.536,2.536h-9.086a.643.643,0,1,0,0,1.285H242.6l-2.536,2.536a.644.644,0,0,0,.908.913l3.787-3.787a.429.429,0,0,0,0-.608Z"
            transform="translate(23300.178 1813.958)"
          />
        </g>
        <rect
          id="Rectangle_17851"
          data-name="Rectangle 17851"
          width="24"
          height="24"
          transform="translate(54 251)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default LogoutIcon;
