import Colors from "../../constants/colors";

const NotificationIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color === Colors.black ? Colors.black : color}
      {...props}
    >
      <g
        id="Group_49327"
        data-name="Group 49327"
        transform="translate(-54 -196)"
      >
        <g
          id="Group_48777"
          data-name="Group 48777"
          transform="translate(-1131.166 182.678)"
        >
          <path
            id="Path_112612"
            data-name="Path 112612"
            d="M178.1,105.608a3.788,3.788,0,0,0-1.78-3.226v-2.51a5.7,5.7,0,0,0-5.386-5.709,5.59,5.59,0,0,0-5.793,5.585v2.633a3.785,3.785,0,0,0-1.78,3.215v.5a.442.442,0,0,0,.442.442h4.237a2.726,2.726,0,0,0,5.382,0h4.237a.442.442,0,0,0,.442-.442Zm-7.37,2.341a1.846,1.846,0,0,1-1.789-1.4h3.578a1.846,1.846,0,0,1-1.789,1.4Zm6.485-2.286h-12.97v-.055a2.9,2.9,0,0,1,1.548-2.567.442.442,0,0,0,.241-.394v-2.9a4.705,4.705,0,0,1,4.869-4.7,4.808,4.808,0,0,1,4.533,4.824v2.775a.442.442,0,0,0,.234.4,2.9,2.9,0,0,1,1.548,2.569Z"
            transform="translate(1026.809 -75.838)"
            stroke="#242424"
            strokeWidth="0.5"
          />
        </g>
        <rect
          id="Rectangle_17850"
          data-name="Rectangle 17850"
          width="24"
          height="24"
          transform="translate(54 196)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default NotificationIcon;
