export const getTotalItemsSelectedValue = (array) => {
  let count = 0;
  for (let i = 0; i < array.length; i++) {
    count = count + array[i].product_selection;
  }
  return count;
};

export const getTotalOrderSelectionValue = (array) => {
  let count = 0;
  for (let i = 0; i < array.length; i++) {
    count = count + array[i].order_selection;
  }
  return count;
};

