import { useState, useEffect, useRef } from "react";
import Text from "../text";
import ArrowDown from "../../assets/icons/arrowDown";
import "./styles.scss";

const Dropdown = ({ selected, options, onClickMenuItem, ...props }) => {
  const [showMenu, setShowMenu] = useState(false);
  
  const calRef = useRef();

  const handleClickOutside = (event) => {
    if (calRef.current && !calRef.current?.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", (event) =>
      handleClickOutside(event)
    );

    return () => {
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(event)
      );
    };
  });

  return (
    <div
      className="dropdown-main-container-style"
      {...props}
      onClick={() => setShowMenu(!showMenu)}
    >
      <Text className="fs-12 fw-b fc-eclipse me-2" value={selected} />
      <ArrowDown />
      {showMenu && (
        <div className="dropdown-menu-container-style" ref={calRef}>
          {options.map((item, index) => (
            <div
              key={index}
              className="dropdown-menu-item-container-style"
              onClick={() => onClickMenuItem(item)}
            >
              <Text className="fs-10 fw-m fc-greySuit" value={item} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
