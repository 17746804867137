import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoginPageIllustration from "../../assets/images/loginPageIllustration";
import EyeOpen from "../../assets/icons/eyeOpen";
import EyeClose from "../../assets/icons/eyeClose";

import Text from "../../components/text";
import Input from "../../components/input";
import Button from "../../components/button";
import ToastContext from "../../components/toast/toastContext";

import { getFirebaseToken } from "../../constants/firebaseConfig";
import { loginAddedUsers } from "../../store/reducer/auth";
import { setLoader } from "../../store/reducer/ui";
import { verifyEmail, verifyPassword, isLoggedIn } from "../../utils/helper";

import "./styles.scss";
import "../../constants/styles.scss";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toastMessage } = useContext(ToastContext);

  const [showHidePassword, setShowHidePassword] = useState("hide");
  const [emailValue, setEmailValue] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");

  useEffect(() => {
    isLoggedIn() && navigate("/");
  });

  const onChangeInput = (event, type) => {
    const value = event.target.value;
    switch (type) {
      case "email":
        setEmailValue(value);
        verifyEmail(value)
          ? setEmailErrorText("")
          : setEmailErrorText("Enter valid email address.");
        break;
      case "password":
        setPasswordValue(value);
        verifyPassword(value)
          ? setPasswordErrorText("")
          : setPasswordErrorText("Password must be at least 8 characters.");
        break;
      default:
        break;
    }
  };

  const onClickShowHidePassword = () => {
    switch (showHidePassword) {
      case "hide":
        setShowHidePassword("show");
        break;
      case "show":
        setShowHidePassword("hide");
        break;
      default:
        break;
    }
  };

  const onClickLogin = async () => {
    dispatch(setLoader(true));
    getFirebaseToken()
      .then(async (firebaseToken) => {
        if (firebaseToken) {
          const data = {
            username: emailValue,
            password: passwordValue,
            role: "head_selector",
            fcmToken: firebaseToken,
          };
          const { error, payload } = await dispatch(loginAddedUsers(data));
          if (error) {
            toastMessage(
              "error",
              "Incorrect Id or Password, or user login on other device"
            );
          } else if (payload.status === 200 && payload.data.status === 1) {
            toastMessage("success", "Successfully Logged");
            navigate("/");
          }
        }
      })
      .catch((err) =>
        console.error(
          "An error occurred while retrieving firebase token. ",
          err
        )
      );
    dispatch(setLoader(false));
  };

  const forgot = () => {
    navigate("/forgot");
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-5 display-flex-column-align-center-justify-center">
          <Text
            className="fw-b fs-22 mb-2 text-center-align"
            value={
              <>
                Selection. Filtration.<br></br>Moderation.
              </>
            }
          />
          <Text
            className="fs-16 mb-4 text-center-align"
            value={
              <>
                Take a look at how we can help<br></br>your product.
              </>
            }
          />
          <LoginPageIllustration className="login-page-illustration-style" />
        </div>
        <div
          className="col-7 login-page-right-side-style 
        display-flex-column-align-center-justify-center"
        >
          <Text
            className="fs-24 fw-b mb-5 text-center-align"
            value="Selector Login"
          />
          <Input
            label="Email Address"
            placeholder="Enter Your Email Address"
            value={emailValue}
            onChange={(event) => {
              onChangeInput(event, "email");
            }}
            errorText={emailErrorText}
          />
          <Input
            label="Password"
            placeholder="Enter Your Password"
            value={passwordValue}
            onChange={(event) => {
              onChangeInput(event, "password");
            }}
            type={
              (showHidePassword === "show" && "text") ||
              (showHidePassword === "hide" && "password")
            }
            errorText={passwordErrorText}
            rightSideIcon={
              (showHidePassword === "show" && (
                <EyeOpen className="login-page-input-right-side-icon-style" />
              )) ||
              (showHidePassword === "hide" && (
                <EyeClose className="login-page-input-right-side-icon-style" />
              ))
            }
            onClickRightSideIcon={onClickShowHidePassword}
          />
          <Button
            className="mb-4"
            text="Login"
            onClick={onClickLogin}
            disabled={
              verifyEmail(emailValue) && verifyPassword(passwordValue)
                ? false
                : true
            }
          />
          <Button type="link" onClick={() => forgot()} text="Forgot Password?" />
        </div>
      </div>
    </div>
  );
};

export default Login;
