import Button from "../../../components/button";
import Text from "../../../components/text";
import Checkbox from "../../../components/checkbox";
import { useEffect, useState } from "react";
import PriceIcon from "../../../assets/icons/price";
import CategoryIcon from "../../../assets/icons/category";
import BrandIcon from "../../../assets/icons/brand";
import Colors from "../../../constants/colors";
import "../../../constants/styles.scss";
import "./styles.scss";
import { Modal } from "react-bootstrap";
import Brand from "./../brands";
import Category from "./../category";
import Color from "./../color";
import Fabric from "./../fabric";
import { Slider, RangeSlider } from "rsuite";
import { propTypes } from "react-bootstrap/esm/Image";

const Filter = ({
  priceArray,
  categoryArray,
  stockArray,
  brandArray,
  colorArray,
  fabricArray,
  onClickCheckBox,
  onClickClearAll,
}) => {
  const [brandState, setBrandState] = useState({
    brands: [],
    rowsToDisplay: 5,
  });
  const [show, setShow] = useState(false);
  const [categoryShow, setCategoryShow] = useState(false);
  const [fabricShow, setFabricShow] = useState(false);
  const [colorShow, setColorShow] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  useEffect(() => {
    setShowSlider(false);
    if (priceArray.length) {
      priceArray[0].minPrice = priceArray[0]?.minPrice
        ? priceArray[0].minPrice
        : 0;
      priceArray[0].maxPrice = priceArray[0]?.maxPrice
        ? priceArray[0].maxPrice
        : 10000;
      setMinPrice(priceArray[0]?.minPrice);
      setMaxPrice(priceArray[0]?.maxPrice);
    }
    setTimeout(() => {
      setShowSlider(true);
    });
    setBrandState({
      brands: brandArray,
      rowsToDisplay: 5,
    });
  }, [brandArray]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleCategoryShow = () => setCategoryShow(true);
  const handleCategoryClose = () => setCategoryShow(false);

  const handleColorShow = () => setColorShow(true);
  const handleColorClose = () => setColorShow(false);

  const handleFabricShow = () => setFabricShow(true);
  const handleFabricClose = () => setFabricShow(false);

  const showMore = () => {
    let brandLength =
      brandState.rowsToDisplay === brandState.brands.length
        ? 5
        : brandState.brands.length;
    setBrandState({ brands: brandState.brands, rowsToDisplay: brandLength });
    // show more entries
    // switch to "show less"
  };

  return (
    <div className="selector-home-product-filter-main-container">
      <div className="selector-home-product-filter-title-container">
        <Text className="fs-16 fw-b fc-nero" value="Filter" />
        <Button type="link" text="Clear all" onClick={onClickClearAll} />
      </div>
      <div className="selector-home-product-filter-line-style"></div>

      {/* Price Range */}
      <div className="selector-home-product-filter-boxes-container">
        <div className="selector-home-product-filter-heading-container">
          <Text className="fs-14 fw-b fc-nero" value="Price Range" />
          <PriceIcon color={Colors.nero} />
        </div>
        {showSlider && (
          <RangeSlider
            min={0}
            max={50000}
            step={100}
            defaultValue={[minPrice, maxPrice]}
            onChange={(value) => {
              onClickCheckBox(value, "price");
            }}
          />
        )}
        {priceArray.map((item, index) => (
          <div
            key={index}
            className="selector-home-product-filter-input-text-container"
          >
            <br />
            <br />
            <Text
              className="fs-12 fc-nero mx-2"
              value={`Rs. ${item.minPrice} - Rs. ${item.maxPrice}`}
            />
            {/* <Text className="fs-12 fc-darkGray" value="(200)" /> */}
          </div>
        ))}
      </div>

      {/* Stock */}
      <div className="selector-home-product-filter-boxes-container">
        <div className="selector-home-product-filter-heading-container">
          <Text className="fs-14 fw-b fc-nero" value="Stock" />
          <CategoryIcon color={Colors.nero} />
        </div>
        {stockArray.map((item, index) => (
          <div
            key={index}
            className="selector-home-product-filter-input-text-container"
          >
            <Checkbox
              checked={item.checked}
              onClick={() => onClickCheckBox(item, "stock")}
            />
            <Text className="fs-12 fc-nero mx-2" value={item.name} />
          </div>
        ))}
      </div>

      {/* Categories */}
      <div className="selector-home-product-filter-boxes-container">
        <div className="selector-home-product-filter-heading-container">
          <Text className="fs-14 fw-b fc-nero" value="Categories" />
          <CategoryIcon color={Colors.nero} />
        </div>
        {categoryArray.slice(0, 5).map((item, index) => (
          <div
            key={index}
            className="selector-home-product-filter-input-text-container"
          >
            <Checkbox
              checked={item.checked}
              onClick={() => onClickCheckBox(item, "category")}
            />
            <Text className="fs-12 fc-nero mx-2" value={item.catType} />
            {/* <Text className="fs-12 fc-darkGray" value="(200)" /> */}
          </div>
        ))}
        <Text
          style={{ color: "#6e62e3", cursor: "pointer" }}
          onClick={handleCategoryShow}
          className="fs-12 fc-nero mx-2"
          value="+ Show more"
        />
      </div>

      {/* Brands */}
      <div className="selector-home-product-filter-boxes-container">
        <div className="selector-home-product-filter-heading-container">
          <Text className="fs-14 fw-b fc-nero" value="Brands" />
          <BrandIcon color={Colors.nero} />
        </div>
        {brandState.brands.slice(0, 5).map((item, index) => (
          <div
            key={index}
            className="selector-home-product-filter-input-text-container"
          >
            <Checkbox
              checked={item.checked}
              onClick={() => onClickCheckBox(item, "brand")}
            />
            <Text className="fs-12 fc-nero mx-2" value={item.BrandName} />
            {/* <Text className="fs-12 fc-darkGray" value="(200)" /> */}
          </div>
        ))}
        {/* <p>
          <span style={{cursor: "pointer"}} onClick={() => showMore()}>{brandState.rowsToDisplay === brandState.brands.length ? 'Show less...' : 'Show more...'}</span>
        </p> */}
        <Text
          style={{ color: "#6e62e3", cursor: "pointer" }}
          onClick={handleShow}
          className="fs-12 fc-nero mx-2"
          value="+ Show more"
        />
      </div>

      {/* Colors */}
      <div className="selector-home-product-filter-boxes-container">
        <div className="selector-home-product-filter-heading-container">
          <Text className="fs-14 fw-b fc-nero" value="Colors" />
          <BrandIcon color={Colors.nero} />
        </div>
        <div className="row">
          {colorArray.slice(0, 7).map((item, index) => (
            <div
              key={index}
              className={`color-container ${
                item.checked ? "color-filter-selected" : ""
              }`}
              onClick={() => onClickCheckBox(item, "color")}
              style={{ "background-color": item.hex_code }}
              title={item.name}
            ></div>
          ))}
        </div>
        <Text
          style={{ color: "#6e62e3", cursor: "pointer", "margin-top": "5px" }}
          onClick={handleColorShow}
          className="fs-12 fc-nero mx-2"
          value="+ Show more"
        />
      </div>

      {/* Fabrics */}
      <div className="selector-home-product-filter-boxes-container">
        <div className="selector-home-product-filter-heading-container">
          <Text className="fs-14 fw-b fc-nero" value="Fabrics" />
          <BrandIcon color={Colors.nero} />
        </div>
        {fabricArray.slice(0, 5).map((item, index) => (
          <div
            key={index}
            className="selector-home-product-filter-input-text-container"
          >
            <Checkbox
              checked={item.checked}
              onClick={() => onClickCheckBox(item, "fabric")}
            />
            <Text className="fs-12 fc-nero mx-2" value={item.name} />
            {/* <Text className="fs-12 fc-darkGray" value="(200)" /> */}
          </div>
        ))}
        {/* <p>
          <span style={{cursor: "pointer"}} onClick={() => showMore()}>{brandState.rowsToDisplay === brandState.brands.length ? 'Show less...' : 'Show more...'}</span>
        </p> */}
        <Text
          style={{ color: "#6e62e3", cursor: "pointer" }}
          onClick={handleFabricShow}
          className="fs-12 fc-nero mx-2"
          value="+ Show more"
        />
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <>
            <Brand brandArray={brandArray} onClickCheckBox={onClickCheckBox} />
          </>
        </Modal.Body>
      </Modal>
      <Modal show={categoryShow} onHide={handleCategoryClose} size="lg">
        <Modal.Body>
          <>
            <Category
              categoryArray={categoryArray}
              onClickCheckBox={onClickCheckBox}
            />
          </>
        </Modal.Body>
      </Modal>
      <Modal show={fabricShow} onHide={handleFabricClose} size="lg">
        <Modal.Body>
          <>
            <Fabric
              fabricArray={fabricArray}
              onClickCheckBox={onClickCheckBox}
            />
          </>
        </Modal.Body>
      </Modal>
      <Modal show={colorShow} onHide={handleColorClose} size="md">
        <Modal.Body>
          <>
            <Color colorArray={colorArray} onClickCheckBox={onClickCheckBox} />
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Filter;
