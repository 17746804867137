import "./styles.scss";
import "../../../constants/styles.scss";
import Text from "../../../components/text";
import { getTotalItemsSelectedValue, getTotalOrderSelectionValue } from "../helper";

const Table = ({ list }) => {
  return (
    <div className="team-performance-table-main-container">
      <div
        className="team-performance-table-item-container 
      team-performance-table-item-border-bottom"
      >
        <div className="team-performance-table-item-first-container">
          <Text value="Name" className="fs-16 fw-b" />
        </div>
        <div className="team-performance-table-item-second-container">
          <Text value="Items Selected" className="fs-16 fw-b" />
        </div>
        <div className="team-performance-table-item-fourth-container">
          <Text value="OSP (%)" className="fs-16 fw-b" />
        </div>
      </div>
      {list?.length > 0 &&
        list.map((item, index) => (
          <div
            key={index}
            className="team-performance-table-item-container 
        team-performance-table-item-container-hover-style"
          >
            <div className="team-performance-table-item-first-container">
              <Text
                value={item.userData?.firstName}
                className="fw-l fs-14"
              />
            </div>
            <div className="team-performance-table-item-second-container">
              <Text value={item.product_selection} className="fw-l fs-14" />
            </div>
            <div className="team-performance-table-item-fourth-container">
              <Text value={item.order_selection} className="fw-b fs-14" />
            </div>
          </div>
        ))}

      {list?.length > 0 && (
        <div
          className="team-performance-table-item-container 
      team-performance-table-bottom-border-radius bg-lavender"
        >
          <div className="team-performance-table-item-first-container"></div>
          <div className="team-performance-table-item-second-container">
            <Text
              value={getTotalItemsSelectedValue(list)}
              className="fw-b fs-14"
            />
          </div>
          {/* <div className="team-performance-table-item-third-container">
          <Text value="29/40" className="fw-b fs-14" />
        </div> */}
          <div className="team-performance-table-item-fourth-container">
            {/* <Text
              value={`${getTotalOrderSelectionValue(list)}%`}
              className="fw-b fs-14"
            /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
