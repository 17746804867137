import Colors from "../../constants/colors";

const BrandIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.542"
      height="17.542"
      viewBox="0 0 17.542 17.542"
      {...props}
    >
      <path
        id="Path_120543"
        data-name="Path 120543"
        d="M113.2,28.818A2.793,2.793,0,0,0,111.144,28l-3.137.1a2.784,2.784,0,0,0-1.885.817L98.5,36.545a1.694,1.694,0,0,0,0,2.393l5.613,5.613a1.694,1.694,0,0,0,2.393,0l7.622-7.622a2.784,2.784,0,0,0,.817-1.885l.1-3.137h0a2.792,2.792,0,0,0-.814-2.058Zm.8,6.194a1.841,1.841,0,0,1-.54,1.246l-7.622,7.622a.746.746,0,0,1-1.054,0l-5.613-5.613a.746.746,0,0,1,0-1.054l7.622-7.622a1.84,1.84,0,0,1,1.246-.54l3.137-.1a1.842,1.842,0,0,1,1.358.537l1.03,1.03a1.843,1.843,0,0,1,.537,1.358Zm-4.966-3.821a2,2,0,1,0,1.413-.584A2,2,0,0,0,109.032,31.192Zm2.157,2.157a1.053,1.053,0,1,1,.308-.744A1.052,1.052,0,0,1,111.189,33.349Z"
        transform="translate(-97.755 -27.753)"
        stroke={color === Colors.black ? Colors.black : color}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default BrandIcon;
