import Button from "../../../components/button";
import Text from "../../../components/text";
import ProfileUser from "../../../assets/images/profileUser.png";
import "../../../constants/styles.scss";
import "./styles.scss";

const Header = ({ userName, onClickProfile }) => {
  return (
    <div className="selector-home-header-container">
      <Text
        className="fs-12 fw-b fc-nero"
        value="Selector"
      />
      <div
        className="selector-home-header-user-profile-container"
        onClick={onClickProfile}
      >
        <img src={ProfileUser} alt="profile-img" height={32} width={32} />
        <div className="selector-home-header-user-profile-text-container">
          <Text
            className="fs-12 fw-b fc-nero"
            value={<>Hi {userName},&nbsp;</>}
          />
          <Text className="fs-12 fc-nero" value="Welcome Back" />
        </div>
      </div>
    </div>
  );
};

export default Header;
