import { createSlice } from "@reduxjs/toolkit";
import {
  selectorAllTheme,
  selectorAllProducts,
  selectorAllBrands,
  selectorAllCategory,
  selectorAddProduct,
  selectorAllFabric,
  selectorAllColor
} from "./actions";

const initialState = {
  selectorAllThemeState: [],
  selectorAllProductsState: [],
  selectorAllBrandsState: [],
  selectorAllCategoryState: [],
  selectorAllFabricsState: [],
  selectorAllColorsState: [],
};

export const HomeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setSelectorAllProductsState: (state, action) => {
      state.selectorAllProductsState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectorAllTheme.fulfilled, (state, { payload }) => {
      const { status, data } = payload;
      if (status === 200 && data.status === 1) {
        state.selectorAllThemeState = data.data;
      } else {
        state.selectorAllThemeState = [];
      }
    });
    builder.addCase(selectorAllProducts.fulfilled, (state, { payload }) => {
      const { status, data } = payload;
      if (status === 200 && data.status === 1) {
        state.selectorAllProductsState = data.data;
      } else {
        state.selectorAllProductsState = [];
      }
    });
    builder.addCase(selectorAllBrands.fulfilled, (state, { payload }) => {
      const { status, data } = payload;
      if (status === 200 && data.status === 1) {
        state.selectorAllBrandsState = data.data;
      } else {
        state.selectorAllBrandsState = [];
      }
    });
    builder.addCase(selectorAllCategory.fulfilled, (state, { payload }) => {
      const { status, data } = payload;
      if (status === 200 && data.status === 1) {
        state.selectorAllCategoryState = data.data;
      } else {
        state.selectorAllCategoryState = [];
      }
    });
    builder.addCase(selectorAllColor.fulfilled, (state, { payload }) => {
      const { status, data } = payload;
      if (status === 200 && data.status === 1) {
        state.selectorAllColorsState = data.data;
      } else {
        state.selectorAllColorsState = [];
      }
    });
    builder.addCase(selectorAllFabric.fulfilled, (state, { payload }) => {
      const { status, data } = payload;
      if (status === 200 && data.status === 1) {
        state.selectorAllFabricsState = data.data;
      } else {
        state.selectorAllFabricsState = [];
      }
    });
    builder.addCase(selectorAddProduct.fulfilled);
  },
});

export const { setSelectorAllProductsState } = HomeSlice.actions;

export {
  selectorAllTheme,
  selectorAllProducts,
  selectorAllBrands,
  selectorAllCategory,
  selectorAddProduct,
  selectorAllFabric,
  selectorAllColor
};
export default HomeSlice.reducer;
