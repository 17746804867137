import api from "../api";
import notificationApiEndpoint from "./config";

const notificationApi = {
  async notificationGetAll() {
    return await api.get(notificationApiEndpoint.notificationGetAll);
  },
  async notificationCountUnread() {
    return await api.get(notificationApiEndpoint.notificationCountUnread);
  },
};

export default notificationApi;
