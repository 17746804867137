import { createSlice } from "@reduxjs/toolkit";
import { loginAddedUsers, forgotpasswordLink } from "./actions";

const initialState = {
  address: null,
  coinsDetails: null,
  countryCode: "",
  createdAt: "",
  email: "",
  fcmToken: null,
  firstName: "",
  gender: "",
  interests: [],
  isHeadSelector: false,
  isLoggedin: false,
  isMyRefCodeActive: false,
  lastLogin: null,
  lastName: "",
  monthlyStipend: 0,
  myRefCode: null,
  notes: "",
  phone: null,
  preference: null,
  profile: null,
  registerType: "",
  role: "",
  size: null,
  tbd: null,
  team: "",
  themes: [],
  token: "",
  total_order_selection: 0,
  total_product_selection: 0,
  updatedAt: "",
  userDetails: null,
  username: "",
  __v: 0,
  _id: "",
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setInitialState: () => {
      console.log('called setInitialState');

      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAddedUsers.fulfilled, (state, { payload }) => {
      const { status, data } = payload;
      if (status === 200 && data.status === 1) {
        return {
          ...state,
          ...data.data,
        };
      } else {
        return initialState;
      }
    });
    builder.addCase(loginAddedUsers.rejected, () => {
      return initialState;
    });
  },
});

export const { setInitialState } = AuthSlice.actions;

export { loginAddedUsers, forgotpasswordLink };
export default AuthSlice.reducer;
