import { createAsyncThunk } from "@reduxjs/toolkit";

import homeApi from "../../../apis/homeApi";
import homeApiEndpoint from "../../../apis/homeApi/config";

export const selectorAllTheme = createAsyncThunk(
  `${homeApiEndpoint.selectorAllTheme}Get`,
  async (payload) => {
    const { status, data } = await homeApi.selectorAllTheme(payload);
    return { status, data };
  }
);

export const selectorAllProducts = createAsyncThunk(
  `${homeApiEndpoint.selectorAllProducts}Post`,
  async (payload) => {
    const { status, data } = await homeApi.selectorAllProducts(payload);
    return { status, data };
  }
);

export const selectorAllBrands = createAsyncThunk(
  `${homeApiEndpoint.selectorAllBrands}Get`,
  async () => {
    const { status, data } = await homeApi.selectorAllBrands();
    return { status, data };
  }
);

export const selectorAllCategory = createAsyncThunk(
  `${homeApiEndpoint.selectorAllCategory}Get`,
  async (payload) => {
    const { status, data } = await homeApi.selectorAllCategory(payload);
    return { status, data };
  }
);

export const selectorAddProduct = createAsyncThunk(
  `${homeApiEndpoint.selectorAddProduct}Post`,
  async (payload) => {
    const { status, data } = await homeApi.selectorAddProduct(payload);
    return { status, data };
  }
);

export const selectorAllFabric = createAsyncThunk(
  `${homeApiEndpoint.selectorAllFabric}Get`,
  async () => {
    const { status, data } = await homeApi.selectorAllFabric();
    return { status, data };
  }
);

export const selectorAllColor = createAsyncThunk(
  `${homeApiEndpoint.selectorAllColor}Get`,
  async () => {
    const { status, data } = await homeApi.selectorAllColor();
    return { status, data };
  }
);

