import React from "react";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

Chart.register(...registerables);

const options = {
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.4, // smooth lines
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
};

const dataFunction = (orderSelectionArray) => {

  const data = {
    labels: orderSelectionArray.xAxis,
    datasets: [
      {
        label: "Order",
        data: orderSelectionArray.yAxisOrder,
        fill: true,
        // backgroundColor: "rgba(75,192,192,0.2)",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "#D1DAFF");
          gradient.addColorStop(1, "#F5F5FF");
          return gradient;
        },
        borderColor: "#7576F9",
      },
      {
        label: "Product",
        data: orderSelectionArray.yAxisProduct,
        fill: true,
        // backgroundColor: "rgba(75,192,192,0.2)",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "#C6B6FD");
          gradient.addColorStop(1, "#F3F3FF");
          return gradient;
        },
        borderColor: "#6E62E3",
      },
    ],
  };
  return data;
};

const LineChart = ({ chartData }) => {
  return <Line data={dataFunction(chartData)} options={options} height={300}  />;
};

export default LineChart;
