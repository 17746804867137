import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setInitialState } from "../../../store/reducer/auth";
import { setLoader } from "../../../store/reducer/ui";
import notificationApi from "../../../apis/notification";
import authApi from "../../../apis/authApi";

import Text from "../../../components/text";
import LogoutIcon from "../../../assets/icons/logout";
import PerformanceIcon from "../../../assets/icons/performance";
import NotificationIcon from "../../../assets/icons/notification";
import WorkTimeIcon from "../../../assets/icons/workTime";
import CartIcon from "../../../assets/icons/cart";
import "./styles.scss";

const Item = ({ icon, text, extraText, onClick, active = false }) => {
  return (
    <div
      onClick={onClick}
      className={`sidebar-item-container ${active ? "bg-whiteSmoke" : ""}`}
    >
      <div className="sidebar-item-icon-container">{icon}</div>
      <Text className="fs-14 fw-b" value={text} />
      {extraText && (
        <div className="sidebar-number-container">
          <Text className="fs-10 fw-eb fc-white" value={extraText} />
        </div>
      )}
    </div>
  );
};

const SideBar = ({ userData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [count, setCount] = useState("0");

  const onClickItem = (route) => {
    navigate(route);
  };

  const onClickLogout = async () => {
    dispatch(setLoader(true));
    await authApi
      .logoutUser()
      .then((res) => {
        dispatch(setInitialState());
        navigate("/login");
      })
      .catch((err) => {
        dispatch(setInitialState());
        navigate("/login");
      });
    dispatch(setLoader(false));
  };

  const listData = () => {
    dispatch(setLoader(true));
    notificationApi.notificationCountUnread().then((res) => {
      if (res.status === 200 && res.data.status === 1) {
        setCount(res.data.data.toString());
      }
    });
    dispatch(setLoader(false));
  };

  useEffect(() => {
    listData();
  }, []);

  return (
    <div className="sidebar-main-container">
      <Item
        active={location.pathname === "/work-hours" ? true : false}
        text="Work Hours"
        icon={<WorkTimeIcon />}
        onClick={() => onClickItem("/work-hours")}
      />
      <Item
        active={location.pathname === "/osp" ? true : false}
        text="OSP"
        icon={<CartIcon />}
        onClick={() => onClickItem("/osp")}
      />
      <Item
        active={location.pathname === "/notifications" ? true : false}
        text="Notifications"
        icon={<NotificationIcon />}
        extraText={count === "0" ? "" : count}
        onClick={() => onClickItem("/notifications")}
      />
      {userData.role === "head_selector" && (
        <Item
          active={location.pathname === "/team" ? true : false}
          text="Team Performance"
          icon={<PerformanceIcon />}
          onClick={() => onClickItem("/team")}
        />
      )}

      <Item text="Logout" icon={<LogoutIcon />} onClick={onClickLogout} />
    </div>
  );
};

export default SideBar;
