import { useState, useEffect } from "react";
import Text from "../../../components/text";
import ErrorIcon from "../../../assets/icons/error";
import SuccessIcon from "../../../assets/icons/success";
import ExclamationIcon from "../../../assets/icons/exclamation";
import ThreeDotIcon from "../../../assets/icons/threeDot";
import Colors from "../../../constants/colors";
import moment from "moment";
import "./styles.scss";
import "../../../constants/styles.scss";

const CalendarBox = ({ item }) => {
  const [className, setClassName] = useState(
    "calendar-box-internal-main-container"
  );

  useEffect(() => {
    getStyles();
  });

  const getStyles = () => {
    if (item.day === 0) {
      setClassName(
        "calendar-box-internal-main-container calendar-box-internal-main-sunday-container"
      );
    }
  };

  // {

  //     "_id": "12-29-2022",
  //     "day": 5,
  //     "product_selection": 1,
  //     "order_selection": 2

  // }

  return (
    <div className="calendar-box-main-container">
      {item.empty === true ? null : (
        <div className={className}>
          <div className="calendar-box-internal-top-container">
            <Text
              value={
                item.date ? moment(item.date, "MM-DD-YYYY").format("DD") : ""
              }
              className="fs-24 fc-nero"
            />
          </div>

          <div className="calendar-box-internal-bottom-container">
            <div className="calendar-box-internal-bottom-container">
              <Text
              value={item.empty === false ? item.order_selection : ""}
                
                className="fs-16 fw-b fc-nero"
              />
              <div className="calendar-box-internal-bottom-dot-saffron-style"></div>
            </div>

            <div className="calendar-box-internal-bottom-container">
              <div className="calendar-box-internal-bottom-dot-medium-slate-blue-style"></div>
              <Text
                value={item.empty === false ? item.product_selection : ""}
                className="fs-16 fc-nero"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarBox;
