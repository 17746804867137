import "./styles.scss";
import "../../../constants/styles.scss";
import Text from "../../../components/text";
import PieChart from "../../../components/chart/pieChart";
import {
  getTotalItemsSelectedValue,
  getTotalOrderSelectionValue,
} from "../helper";

const Chart = ({ list, orderSelectionArray }) => {
  return (
    <div className="team-performance-chart-main-container">
      <Text
        className="dashboard-pages-reusable-title-style"
        value="Statistics"
      />
      {list?.length > 0 && (
        <div className="team-performance-chart-inside-main-container">
          <div className="team-performance-chart-inside-container">
            <Text className="fs-18 fw-b fc-nero" value="Daywise" />
            <div className="team-performance-chart-info-box-main-container">
              <div className="team-performance-chart-dark-box-main-style">
                <Text className="fs-16 fc-white me-2" value="Selected" />
                <Text
                  className="fs-16 fw-eb fc-white"
                  value={
                    list?.length > 0 ? getTotalItemsSelectedValue(list) : ""
                  }
                />
              </div>

              {/* <div className="team-performance-chart-light-box-main-style">
                <Text
                  className="fs-10 fc-mediumSlateBlue"
                  value="Rate of success"
                />
                <Text
                  className="fs-26 fw-b fc-mediumSlateBlue"
                  value={
                    list?.length > 0
                      ? `${getTotalOrderSelectionValue(list)}%`
                      : ""
                  }
                />
              </div> */}
            </div>
          </div>
          <PieChart orderSelectionArray={orderSelectionArray} />
        </div>
      )}
    </div>
  );
};

export default Chart;
