import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useContext } from "react";
import PrivateRouter from "../components/privateRouter";
import Login from "../containers/login";
import Forgot from "../containers/forgot";
import Dashboard from "../containers/dashboard";
import SelectorHome from "../containers/selectorHome";
import ToastContext from "../components/toast/toastContext";
import { messaging } from "../constants/firebaseConfig";
import { onMessage } from "firebase/messaging";

const MainRoutes = () => {
  const { toastMessage } = useContext(ToastContext);
  onMessage(messaging, (payload) => {
    toastMessage(payload?.notification?.title, payload?.notification?.body);
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="forgot" element={<Forgot />} />
        <Route
          path="/"
          element={<PrivateRouter children={<SelectorHome />} />}
        />
        <Route path="*" element={<PrivateRouter children={<Dashboard />} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
