import { useState, useEffect, useContext } from "react";
import Text from "../../components/text";
import NotificationBox from "./notificationBox";
import notificationApi from "../../apis/notification";
import moment, { end } from "moment";
import "./styles.scss";
import "../../constants/styles.scss";

const Notifications = () => {
  const [notificationListArray, setNotificationListArray] = useState([]);

  const listData = () => {
    notificationApi
      .notificationGetAll()
      .then((res) => {
        if (res.status === 200 && res.data.status === 1) {
          setNotificationListArray(res.data.data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    listData();
  }, []);

  const getNotificationDuration = (time) => {
    let end = moment();
    let duration = moment.duration(end.diff(time));
    let diff;
    if (duration.asHours() <= 24) {
      diff = duration.asHours();
      return `${parseInt(diff)} h`;
    } else {
      diff = duration.asDays();
      return `${parseInt(diff)} d`;
    }
  };

  return (
    <div className="container-fluid dashboard-pages-reusable-style">
      <Text
        className="dashboard-pages-reusable-title-style"
        value="Notifications"
      />
      <Text className="fs-14 fw-b fc-grey mb-3" value="Today" />
      {notificationListArray.map((item) => (
        <NotificationBox
          key={item._id}
          type={item.notificationType}
          message={item.message}
          time={getNotificationDuration(item.createdAt)}
        />
      ))}
    </div>
  );
};

export default Notifications;
