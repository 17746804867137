import { useState, useEffect, useRef } from "react";
import Text from "../text";
import CalendarIcon from "../../assets/icons/calender";
import Colors from "../../constants/colors";
import "./styles.scss";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Calender = ({
  value = "",
  setValue,
  className,
  placeholder,
  minDate,
  maxDate,
}) => {
  const [showCalendar, setShowCalendar] = useState(true);

  const calRef = useRef();

  const handleClickOutside = (event) => {
    if (calRef.current && !calRef.current?.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", (event) =>
      handleClickOutside(event)
    );

    return () => {
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(event)
      );
    };
  });

  const onChangeCalender = (date) => {
    setShowCalendar(false);
    setValue(date);
  };

  return (
    <div className={`${className} calendar-main-container`}>
      <div
        className="calendar-main-text-container"
        onClick={() => setShowCalendar(!showCalendar)}
      >
        <Text
          value={value === "" ? placeholder : value}
          className="fs-12 fw-b fc-eclipse me-2"
        />
        <div className="calendarIconContainer">
          <CalendarIcon color={Colors.eclipse} />
        </div>
      </div>

      {showCalendar && (
        <div className="calendar-component-container" ref={calRef}>
          <Calendar
            selectRange={true}
            onChange={(date) => {
              onChangeCalender(date);
            }}
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
          />
        </div>
      )}
    </div>
  );
};

export default Calender;
