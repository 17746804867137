import { Routes, Route} from "react-router-dom";
import TeamPerformance from "../containers/teamPerformance";
import Notifications from "../containers/notifications";
import OrderSelectionPercentage from "../containers/orderSelectionPercentage";
import WorkHours from "../containers/workHours";


const DashboardRoutes = () => {
  return (
      <Routes>
        <Route path="team" element={<TeamPerformance/>} />
        <Route path="notifications" element={<Notifications/>} />
        <Route path="osp" element={<OrderSelectionPercentage/>} />
        <Route path="work-hours" element={<WorkHours/>} />
      </Routes>
  );
};

export default DashboardRoutes;
