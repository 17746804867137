import api from "../api";
import { teamApiEndpoint } from "./config";

const teamApi = {
  async teamListAll(payload) {
    return await api.post(teamApiEndpoint.teamList, payload);
  },
};

export default teamApi;
