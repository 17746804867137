import Colors from "../../constants/colors";

const WorkTimeIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color === Colors.black ? Colors.black : color}
      {...props}
    >
      <g
        id="Group_49332"
        data-name="Group 49332"
        transform="translate(-32 -86)"
      >
        <g id="Group_49325" data-name="Group 49325" transform="translate(-22)">
          <rect
            id="Rectangle_17848"
            data-name="Rectangle 17848"
            width="24"
            height="24"
            transform="translate(54 86)"
            fill="none"
          />
        </g>
        <g
          id="noun-working-hours-4457405"
          transform="translate(-69.928 53.686)"
        >
          <path
            id="Path_120532"
            data-name="Path 120532"
            d="M221.668,37.528A2.528,2.528,0,0,1,224.2,35h1.011a2.528,2.528,0,0,1,2.528,2.528V38.54a.506.506,0,0,1-.506.506h-5.057a.506.506,0,0,1-.506-.506Zm2.528-1.517a1.517,1.517,0,0,0-1.517,1.517v.506h4.045v-.506a1.517,1.517,0,0,0-1.517-1.517Z"
            transform="translate(-111.611)"
            fillRule="evenodd"
          />
          <path
            id="Path_120533"
            data-name="Path 120533"
            d="M105,107.526A2.528,2.528,0,0,1,107.528,105h11.125a2.528,2.528,0,0,1,2.528,2.528v2.023H120.17v-2.023a1.517,1.517,0,0,0-1.517-1.517H107.528a1.517,1.517,0,0,0-1.517,1.517v6.068a1.517,1.517,0,0,0,1.517,1.517h5.563v1.011h-5.563A2.528,2.528,0,0,1,105,113.594Z"
            transform="translate(0 -66.964)"
            stroke="#000"
            strokeWidth="0.2"
            fillRule="evenodd"
          />
          <path
            id="Path_120534"
            data-name="Path 120534"
            d="M105,107.526A2.528,2.528,0,0,1,107.528,105h11.125a2.528,2.528,0,0,1,2.528,2.528v2.023a.506.506,0,0,1-.506.506h-5.8v-1.011h5.294v-1.517a1.517,1.517,0,0,0-1.517-1.517H107.529a1.517,1.517,0,0,0-1.517,1.517v1.517h5.231v1.011h-5.736a.506.506,0,0,1-.506-.506Z"
            transform="translate(0 -66.964)"
            stroke="#000"
            strokeWidth="0.2"
            fillRule="evenodd"
          />
          <path
            id="Path_120535"
            data-name="Path 120535"
            d="M235.715,178.075a2.427,2.427,0,1,1,4.678,0l-.166.6a.506.506,0,0,1-.487.37h-3.371a.506.506,0,0,1-.487-.37Zm2.339-2.066a1.416,1.416,0,0,0-1.364,1.8l.063.228h2.6l.063-.228A1.416,1.416,0,0,0,238.054,176.009Z"
            transform="translate(-124.963 -133.93)"
            fillRule="evenodd"
          />
          <g
            id="Group_49331"
            data-name="Group 49331"
            transform="translate(114.11 44.694)"
          >
            <path
              id="Path_120536"
              data-name="Path 120536"
              d="M342.795,269.142a3.653,3.653,0,1,0,3.653,3.653A3.653,3.653,0,0,0,342.795,269.142Zm-4.465,3.653a4.464,4.464,0,1,1,4.465,4.464A4.465,4.465,0,0,1,338.33,272.795Z"
              transform="translate(-338.33 -268.33)"
              stroke="#000"
              strokeWidth="0.2"
              fillRule="evenodd"
            />
            <path
              id="Path_120537"
              data-name="Path 120537"
              d="M118.37,44.31v4.033h4.015"
              transform="translate(-114.025 -43.505)"
              fill="none"
              stroke={color === Colors.black ? Colors.black : color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WorkTimeIcon;
