import api from "../api";
import { ospApiEndpoint } from "./config";

const ospApi = {
  async ospAll() {
    return await api.get(ospApiEndpoint.ospAll);
  },
  async performanceByDate(payload) {
    return await api.post(ospApiEndpoint.performanceByDate, payload);
  },
};

export default ospApi;
