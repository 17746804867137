import React from "react";
import { Chart, registerables, Colors } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables);
Chart.register(ChartDataLabels);
Chart.register(Colors);

const dataFunction = (orderSelectionArray) => {
  const data = {
    datasets: [
      {
        backgroundColor: ["#6E62E3", "#7576F9", "#9092FA", "#ACAFFB"],
        data: orderSelectionArray,
        borderWidth: 0,
      },
    ],
  };

  return data;
};

const options = {
  showTooltips: false,
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
        drawBorder: false,
        zeroLineColor: "transparent",
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // <-- this option disables tooltips
    },
    title: {
      display: false,
      text: "Sprint Velocity",
      padding: {
        bottom: 20,
      },
      weight: "bold",
      color: "#00325c",
      font: {
        size: 13,
      },
      align: "center",
    },
    datalabels: {
      color: "white",
      labels: {
        title: {
          font: {
            weight: "bold",
            size: 20,
            color: "white",
          },
        },
      },
      formatter: (value) => {
        return `${value}%`;
      },
    },
    colors: {
      forceOverride: false,
    },
  },
};
// style={{height:'100px',width:'200px'}}
const PieChart = ({ orderSelectionArray }) => (
  <div
    style={{
      height: "372px",
      width: "372px",
      marginBottom: "1%",
      padding: "1%",
      display: "flex",
      flexDirection: "row",
    }}
  >
    <Pie data={dataFunction(orderSelectionArray)} options={options} />
  </div>
);

export default PieChart;
