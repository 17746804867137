import { useState } from "react";
import CheckIcon from "../../assets/icons/check";
import Colors from "../../constants/colors";
import "./styles.scss";

const Checkbox = ({ checked = false, onClick, ...props }) => {
  

  return (
    <div
      className={
        checked ? "checkbox-main-active-container" : "checkbox-main-container"
      }
      onClick={onClick}
      {...props}
    >
      {checked ? <CheckIcon color={Colors.white} /> : null}
    </div>
  );
};

export default Checkbox;
