import Colors from "../../constants/colors";

const ArrowDown = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.71"
      height="6.087"
      viewBox="0 0 9.71 6.087"
      {...props}
    >
      <path
        id="Path_112613"
        data-name="Path 112613"
        d="M208.484,207.371a.929.929,0,0,1-.517-.221l-3.9-4.208a.929.929,0,1,1,1.208-1.411l3.3,3.691,3.3-3.691a.929.929,0,1,1,1.208,1.411l-3.9,4.208a.929.929,0,0,1-.691.221Z"
        transform="translate(-203.717 -201.288)"
        fill={color === Colors.black ? Colors.black : color}
      />
    </svg>
  );
};

export default ArrowDown;
