import Button from "../../../components/button";
import Text from "../../../components/text";
import Checkbox from "../../../components/checkbox";
import { useEffect, useState } from "react";
import "../../../constants/styles.scss";
import "./styles.scss";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const Category = (props) => {
  const [category, setCategory] = useState([]);
  const [labelsArr, setLabelsArr] = useState([
    {
      name: "Hyuzter",
      checked: false,
    },
    {
      name: "GramGali",
      checked: false,
    },
    {
      name: "UPfashion",
      checked: false,
    },
    {
      name: "Khem Design",
      checked: false,
    },
    {
      name: "Priya Wear",
      checked: false,
    },
    {
      name: "Aajee",
      checked: false,
    }
  ]);
  const [alpha, setAlpha] = useState([
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ]);
  useEffect(() => {
    let arr = [];
    alpha.map((item) => {
      const arrChar = props.categoryArray.filter((b) =>
        b.catType.toLowerCase().startsWith(item.toLowerCase())
      );
      if (arrChar.length) {
        arr.push({
          key: item,
          arr: arrChar,
        });
      }
    });
    setCategory(arr);
  }, [props.categoryArray]);

  return (
    <div className="">
      {/* Category */}
      <div className="selector-home-product-filter-boxes-container">
        <Container>
          <Row>
            <Col xs={12} md={12} className="main-category">
              <Row>
                {category.map((item) => (
                  <>
                    <Col xs={4} md={4}>
                      <div className="selector-home-product-filter-heading-container">
                        <Text className="fs-14 fw-b fc-nero" value={item.key} />
                      </div>
                      {item.arr.map((category, index) => (
                        <div
                          key={index + item.key}
                          className="selector-home-product-filter-input-text-container"
                        >
                          <Checkbox
                            checked={category.checked}
                            onClick={() =>
                              props.onClickCheckBox(category, "category")
                            }
                          />
                          <Text
                            className="fs-12 fc-nero mx-2"
                            value={category.catType}
                          />
                        </div>
                      ))}
                    </Col>
                  </>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Category;
