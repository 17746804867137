import Colors from "../../constants/colors";

const ArrowLeft = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.287"
      height="14.074"
      viewBox="0 0 8.287 14.074"
      {...props}
    >
      <path
        id="Path_120540"
        data-name="Path 120540"
        d="M6855.159,2098.3l-5.27,5.27,5.27,5.27"
        transform="translate(-6848.639 -2096.534)"
        fill="none"
        stroke={color === Colors.black ? Colors.black : color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  );
};

export default ArrowLeft;
