import "./styles.scss";
import "../../../constants/styles.scss";
import Text from "../../../components/text";
import LineChart from "../../../components/chart/lineChart";
import Dropdown from "../../../components/dropdown";
import Calender from "../../../components/calender";

const Chart = ({
  orderSelectionArray,
  dropDownSelectedItem,
  dropDownOption,
  onClickMenuItem,
  onChangeCalenderValue,
  calenderValue,
  calenderMinDate,
  calenderMaxDate,
}) => {

  return (
    <div className="order-selection-percentage-chart-main-container">
      <div className="order-selection-percentage-title-container-style">
        <Text
          className="dashboard-pages-reusable-title-style"
          value="Statistics"
        />
        <div className="order-selection-percentage-title-filters-container-style">
          <Calender
            placeholder="Start Date - End Date"
            className="me-2"
            value={calenderValue}
            setValue={(data) => {
              onChangeCalenderValue(data);
            }}
            minDate={calenderMinDate}
            maxDate={calenderMaxDate}
          />

          <Dropdown
            selected={dropDownSelectedItem}
            options={dropDownOption}
            onClickMenuItem={(item) => onClickMenuItem(item)}
          />
        </div>
      </div>

      <div className="order-selection-percentage-chart-inside-main-container">
        <LineChart chartData={orderSelectionArray} />
      </div>
    </div>
  );
};

export default Chart;
