import Colors from "../../constants/colors";

const PriceIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.687"
      height="12"
      viewBox="0 0 14.687 12"
      {...props}
    >
      <g
        id="Group_48729"
        data-name="Group 48729"
        transform="translate(-1354.282 -689)"
      >
        <path
          id="Path_112588"
          data-name="Path 112588"
          d="M106.176,82H95.52a2,2,0,0,1-2-2V72a2,2,0,0,1,2-2h10.672a2,2,0,0,1,2,2v8a2.025,2.025,0,0,1-2.016,2ZM95.52,71.328a.676.676,0,0,0-.672.672v8a.676.676,0,0,0,.672.672h10.672a.676.676,0,0,0,.672-.672V72a.676.676,0,0,0-.672-.672Z"
          transform="translate(1260.777 619)"
          fill={color === Colors.black ? Colors.black : color}
        />
        <path
          id="Path_112589"
          data-name="Path 112589"
          d="M106.976,199.024H93.632a.672.672,0,0,1,0-1.344H106.96a.676.676,0,0,1,.672.672.663.663,0,0,1-.656.672Z"
          transform="translate(1261.322 494.968)"
          fill={color === Colors.black ? Colors.black : color}
        />
      </g>
    </svg>
  );
};

export default PriceIcon;
