import Button from "../../../components/button";
import Text from "../../../components/text";
import BrandIcon from "../../../assets/icons/brand";
import "../../../constants/styles.scss";
import "./styles.scss";

const Rules = ({ content }) => {
  return (
    <div
      className="selector-home-rule-container"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};

export default Rules;
