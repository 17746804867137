import Colors from "../../constants/colors";

const LoaderIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      style={{
        margin: "auto",
        background: "rgba(255, 255, 255, 0)",
        display: "block",
        shapeRendering: "auto",
        animationPlayState: "running",
        animationDelay: "0s",
      }}
      width={200}
      height={200}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={color === Colors.black ? Colors.black : color}
        strokeWidth="15"
        r="40"
        strokeDasharray="188.49555921538757 64.83185307179586"
        style={{ animationPlayState: "running", animationDelay: "0s" }}
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animateTransform>
      </circle>
    </svg>
  );
};

export default LoaderIcon;
