import Button from "../../../components/button";
import {endPoint} from "../../../apis/api"
import Text from "../../../components/text";
import "../../../constants/styles.scss";
import "./styles.scss";

const ItemBox = ({ item, selected = false, onClick }) => {
  return (
    <div className="selector-home-item-box-main-container" onClick={onClick}>
      <div
        className={
          selected
            ? "selector-home-item-box-image-container-selected"
            : "selector-home-item-box-image-container"
        }
      >
        <img
          src={`https://snuggle-assets.s3.ap-south-1.amazonaws.com/${item.banner_image}`}
          alt="bannerImage"
          width="70%"
          height="auto"
        />
      </div>

      <Text className="fs-12 fc-nero" value={item.title} />
    </div>
  );
};

export default ItemBox;
