import "./styles.scss";

const Button = ({ text, className, type = "normal", ...props }) => {
  const getStyle = () => {
    switch (type) {
      case "normal":
        return className
          ? `${className} button-normal-style`
          : "button-normal-style";
      case "link":
        return className
          ? `${className} button-link-style`
          : "button-link-style";
      default:
        break;
    }
  };

  return <button className={getStyle()} {...props}>{text}</button>;
};

export default Button;
