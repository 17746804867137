import Colors from "../../constants/colors";

const SuccessIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.08"
      height="20.868"
      viewBox="0 0 20.08 20.868"
      {...props}
    >
      <path
        id="Path_112634"
        data-name="Path 112634"
        d="M179.9,90.352a1.743,1.743,0,0,0-3.19-.081l-.157-.581a1.743,1.743,0,0,0-2.906-.488l-1.662-1.674a1.877,1.877,0,0,0-2.58,0,.71.71,0,0,1-.052.07l-.721-.721a1.865,1.865,0,0,0-2.58,0,1.917,1.917,0,0,0-.459.8l-.058-.058a1.877,1.877,0,0,0-2.58,0,1.836,1.836,0,0,0,0,2.58l.064.058a1.809,1.809,0,0,0-.8,3.033l.186.186a1.778,1.778,0,0,0-.825.442,1.83,1.83,0,0,0,0,2.58l5.352,5.352a5.428,5.428,0,0,0,3.847,1.586,5.633,5.633,0,0,0,.7-.046,5.42,5.42,0,0,0,2.679.709,5.9,5.9,0,0,0,3.824-1.441l1.807-1.767a4.55,4.55,0,0,0,1.319-2.935,22.821,22.821,0,0,0-1.209-7.607Zm-9.832-2.139a.912.912,0,0,1,1.255,0l1.883,1.889a1.953,1.953,0,0,0,0,.256v1.1l-3.179-3.179a.573.573,0,0,1,.041-.07Zm-2.47,13-5.352-5.352a.883.883,0,0,1,0-1.255.907.907,0,0,1,1.255,0l2.906,2.906a.476.476,0,0,0,.662,0,.471.471,0,0,0,0-.662l-4.178-4.178a.878.878,0,0,1-.285-.645.86.86,0,0,1,.267-.628.889.889,0,0,1,1.255,0l4.178,4.184a.476.476,0,0,0,.662,0,.471.471,0,0,0,0-.662l-5.358-5.358a.871.871,0,0,1-.262-.628.9.9,0,0,1,.262-.633.912.912,0,0,1,1.255,0l1.162,1.162,4.172,4.178a.471.471,0,0,0,.662-.662L166.686,88.8a.883.883,0,0,1,0-1.255.9.9,0,0,1,1.261,0L173.3,92.9a.477.477,0,0,0,.511.1.471.471,0,0,0,.285-.43V90.352a.787.787,0,0,1,.041-.256.814.814,0,0,1,.767-.581.831.831,0,0,1,.75.465c.349,1.249.628,2.324.843,3.376A15.327,15.327,0,0,1,176.8,97.2a3.557,3.557,0,0,1-1.052,2.324L174,101.341A4.841,4.841,0,0,1,171.5,102.5a4.492,4.492,0,0,1-3.905-1.29Zm12.581-3.336a3.608,3.608,0,0,1-1.052,2.324l-1.778,1.8a4.8,4.8,0,0,1-4.376,1.029,6.087,6.087,0,0,0,1.656-.97l1.8-1.807a4.493,4.493,0,0,0,1.325-2.94,15.92,15.92,0,0,0-.32-4.027l.035-2.266a.819.819,0,0,1,.825-.825.808.808,0,0,1,.732.465,22.143,22.143,0,0,1,1.151,7.223ZM168.243,85.592a.466.466,0,0,0,.668-.651l-.982-1.023a.471.471,0,0,0-.674.651Zm2.324-.267a.465.465,0,0,0,.465-.465V83.7a.465.465,0,1,0-.93,0V84.86a.465.465,0,0,0,.447.465Zm1.964.407a.476.476,0,0,0,.325-.134l.971-.976a.465.465,0,0,0-.651-.662l-.988.965a.459.459,0,0,0,0,.657.453.453,0,0,0,.325.151Z"
        transform="translate(-161.051 -83.233)"
        fill={color === Colors.black ? Colors.black : color}
      />
    </svg>
  );
};

export default SuccessIcon;
