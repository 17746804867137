import api from "../api";
import homeApiEndpoint from "./config";

const homeApi = {
  async selectorAllTheme(gender) {
    const url = `${homeApiEndpoint.selectorAllTheme}?gender=${gender}`;
    return await api.get(url);
  },
  async selectorAllProducts(payload) {
    return await api.post(homeApiEndpoint.selectorAllProducts, payload);
  },
  async selectorAllBrands() {
    return await api.get(homeApiEndpoint.selectorAllBrands);
  },
  async selectorAllCategory(gender) {
    const url = `${homeApiEndpoint.selectorAllCategory}/${gender}/getAll`;
    return await api.get(url);
  },
  async selectorAddProduct(payload) {
    return await api.post(homeApiEndpoint.selectorAddProduct, payload);
  }, 
  async selectorAllFabric() {
    return await api.get(homeApiEndpoint.selectorAllFabric);
  }, 
  async selectorAllColor() {
    return await api.get(homeApiEndpoint.selectorAllColor);
  }, 
};

export default homeApi;
