import api from "../api";
import workHoursApiEndpoint from "./config";

const workHoursApi = {
  async selectorPerformanceCalendar(payload) {
    return await api.post(workHoursApiEndpoint.selectorPerformanceCalendar, payload);
  },
};

export default workHoursApi;
