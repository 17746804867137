import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { getRandomNumber } from "../../utils/helper";

import Text from "../../components/text";
import Button from "../../components/button";
import ItemBox from "./itemBox";
import Filter from "./filter";
import Rules from "./rules";
import ProductCarousel from "./productCarousel";
import Header from "./header";

import {
  selectorAllTheme,
  selectorAllProducts,
  selectorAllBrands,
  selectorAllCategory,
  setSelectorAllProductsState,
  selectorAddProduct,
  selectorAllFabric,
  selectorAllColor,
} from "../../store/reducer/home";
import { setLoader } from "../../store/reducer/ui";

import "../../constants/styles.scss";
import "./styles.scss";

const SelectorHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authData = useSelector((state) => state.auth);

  const selectorAllThemeData = useSelector(
    (state) => state.home.selectorAllThemeState
  );
  const selectorAllBrandsData = useSelector(
    (state) => state.home.selectorAllBrandsState
  );
  const selectorAllCategoryData = useSelector(
    (state) => state.home.selectorAllCategoryState
  );
  const selectorAllProductsData = useSelector(
    (state) => state.home.selectorAllProductsState
  );
  const selectorAllFabricsData = useSelector(
    (state) => state.home.selectorAllFabricsState
  );
  const selectorAllColorsData = useSelector(
    (state) => state.home.selectorAllColorsState
  );

  const [genderConfigValue, setGenderConfigValue] = useState("male");
  const [selectedThemeValue, setSelectedThemeValue] = useState({});
  const [allThemeArrayValue, setAllThemeArrayValue] = useState([]);
  const [priceArrayForFilter, setPriceArrayForFilter] = useState([]);
  const [categoryArrayForFilter, setCategoryArrayForFilter] = useState([]);
  const [brandArrayForFilter, setBrandArrayForFilter] = useState([]);
  const [fabricArrayForFilter, setFabricArrayForFilter] = useState([]);
  const [colorArrayForFilter, setColorArrayForFilter] = useState([]);
  const [stockArr, setStockArr] = useState([
    { name: "Warehouse", checked: false },
    { name: "Listed", checked: false },
    { name: "Unlisted", checked: true },
  ]);

  const callInitialApis = async () => {
    dispatch(setLoader(true));
    dispatch(selectorAllTheme(genderConfigValue));
    dispatch(selectorAllBrands());
    dispatch(selectorAllCategory(genderConfigValue));
    dispatch(selectorAllFabric());
    dispatch(selectorAllColor());
    dispatch(setLoader(false));
  };

  useEffect(() => {
    callInitialApis();
  }, [genderConfigValue]);

  useEffect(() => {
    addSelectedToSelectorAllThemeData();
  }, [selectorAllThemeData]);

  const addSelectedToSelectorAllThemeData = () => {
    const selectorAllThemeDataCopy = cloneDeep(selectorAllThemeData);
    for (let i = 0; i < selectorAllThemeDataCopy.length; i++) {
      if (i === 0) {
        selectorAllThemeDataCopy[i].selected = true;
      } else {
        selectorAllThemeDataCopy[i].selected = false;
      }
    }
    setSelectedThemeValue(selectorAllThemeDataCopy[0]);
    setAllThemeArrayValue(selectorAllThemeDataCopy);
  };

  useEffect(() => {
    createArrayForFilter();
  }, [
    selectedThemeValue,
    selectorAllBrandsData,
    selectorAllCategoryData,
    selectorAllFabricsData,
    selectorAllColorsData,
  ]);

  const createArrayForFilter = () => {
    if (
      selectorAllBrandsData &&
      selectorAllCategoryData &&
      selectorAllFabricsData &&
      selectorAllColorsData &&
      selectedThemeValue
    ) {
      const priceArr = [
        {
          _id: getRandomNumber(),
          checked: true,
          maxPrice: selectedThemeValue.maxPrice,
          minPrice: selectedThemeValue.minPrice,
        },
      ];
      setPriceArrayForFilter(priceArr);

      setStockArr([
        { name: "Warehouse", checked: false },
        { name: "Listed", checked: false },
        { name: "Unlisted", checked: true },
      ])

      const categoryCopy = cloneDeep(selectorAllCategoryData);

      for (let i = 0; i < categoryCopy.length; i++) {
        const isIncludes = selectedThemeValue.categories?.includes(
          categoryCopy[i]?.catType
        );
        if (isIncludes === true) {
          categoryCopy[i].checked = true;
        } else {
          categoryCopy[i].checked = false;
        }
      }
      setCategoryArrayForFilter(categoryCopy);

      const brandCopy = cloneDeep(selectorAllBrandsData);
      for (let i = 0; i < brandCopy.length; i++) {
        const isIncludes = selectedThemeValue.brands?.includes(
          brandCopy[i]?.BrandName
        );
        if (isIncludes === true) {
          brandCopy[i].checked = true;
        } else {
          brandCopy[i].checked = false;
        }
      }
      setBrandArrayForFilter(brandCopy);

      const fabricCopy = cloneDeep(selectorAllFabricsData);
      for (let i = 0; i < fabricCopy.length; i++) {
        const isIncludes = selectedThemeValue.fabric?.includes(
          fabricCopy[i]?.name
        );
        if (isIncludes === true) {
          fabricCopy[i].checked = true;
        } else {
          fabricCopy[i].checked = false;
        }
      }
      setFabricArrayForFilter(fabricCopy);

      const colorCopy = cloneDeep(selectorAllColorsData);
      for (let i = 0; i < colorCopy.length; i++) {
        const isIncludes = selectedThemeValue.color?.includes(
          colorCopy[i]?.value
        );
        if (isIncludes === true) {
          colorCopy[i].checked = true;
        } else {
          colorCopy[i].checked = false;
        }
      }
      setColorArrayForFilter(colorCopy);
    }
  };

  const onClickFilterCheckBox = (item, type) => {
    let index;

    switch (type) {
      case "price":
        const priceArrayForFilterCopy = cloneDeep(priceArrayForFilter);
        priceArrayForFilterCopy[0].checked = true;
        priceArrayForFilterCopy[0].minPrice = item[0];
        priceArrayForFilterCopy[0].maxPrice = item[1];
        setPriceArrayForFilter(priceArrayForFilterCopy);
        break;

      case "stock":
        const newArr = stockArr.map(x => {
          x.checked = x.name == item.name ? true : false;
          return x;
        })
        setStockArr(newArr);
        break;

      case "category":
        const categoryArrayForFilterCopy = cloneDeep(categoryArrayForFilter);
        index = categoryArrayForFilterCopy.findIndex((x) => x._id === item._id);
        item.checked = !item.checked;
        categoryArrayForFilterCopy.splice(index, 1, item);
        setCategoryArrayForFilter(categoryArrayForFilterCopy);
        break;

      case "brand":
        const brandArrayForFilterCopy = cloneDeep(brandArrayForFilter);
        index = brandArrayForFilterCopy.findIndex((x) => x._id === item._id);
        item.checked = !item.checked;
        brandArrayForFilterCopy.splice(index, 1, item);
        setBrandArrayForFilter(brandArrayForFilterCopy);
        break;
      case "color":
        const colorArrayForFilterCopy = cloneDeep(colorArrayForFilter);
        index = colorArrayForFilterCopy.findIndex((x) => x._id === item._id);
        item.checked = !item.checked;
        colorArrayForFilterCopy.splice(index, 1, item);
        setColorArrayForFilter(colorArrayForFilterCopy);
        break;
      case "fabric":
        const fabricArrayForFilterCopy = cloneDeep(fabricArrayForFilter);
        index = fabricArrayForFilterCopy.findIndex((x) => x._id === item._id);
        item.checked = !item.checked;
        fabricArrayForFilterCopy.splice(index, 1, item);
        setFabricArrayForFilter(fabricArrayForFilterCopy);
        break;
      case "label":
        break;
      default:
        break;
    }
  };

  const onClickClearAll = () => {
    let price = priceArrayForFilter.map((item) => {
      item.checked = false;
      item.minPrice = 0;
      item.maxPrice = 50000;
      return item;
    });
    setPriceArrayForFilter(price);

    const newArr = stockArr.map(x => {
      x.checked = false;
      return x;
    })
    setStockArr(newArr);

    let category = categoryArrayForFilter.map((item) => {
      item.checked = false;
      return item;
    });
    setCategoryArrayForFilter(category);

    let brand = brandArrayForFilter.map((item) => {
      item.checked = false;
      return item;
    });
    setBrandArrayForFilter(brand);

    let color = colorArrayForFilter.map((item) => {
      item.checked = false;
      return item;
    });
    setColorArrayForFilter(color);

    let fabric = fabricArrayForFilter.map((item) => {
      item.checked = false;
      return item;
    });
    setFabricArrayForFilter(fabric);
  };

  const callSelectorAllProductsApi = () => {
    dispatch(setLoader(true));
    if (
      priceArrayForFilter.length > 0 &&
      categoryArrayForFilter.length > 0 &&
      brandArrayForFilter.length > 0
    ) {
      let brand = [];
      brandArrayForFilter.forEach((x) => {
        if (x?.checked) {
          brand.push(x.BrandName);
        }
      });

      let color = [];
      colorArrayForFilter.forEach((x) => {
        if (x?.checked) {
          color.push(x.name);
        }
      });

      let category = [];
      categoryArrayForFilter.forEach((x) => {
        if (x?.checked) {
          category.push(x.catType);
        }
      });

      let fabric = [];
      fabricArrayForFilter.forEach((x) => {
        if (x?.checked) {
          fabric.push(x.name);
        }
      });

      let stock = "";
      stockArr.forEach((x) => {
        if (x?.checked) {
          stock = x.name;
        }
      });

      const payload = {
        stock,
        brand,
        category,
        color,
        fabric,
        gender: genderConfigValue,
        themeId: selectedThemeValue?._id,
        minPrice:
          priceArrayForFilter[0].checked === true
            ? priceArrayForFilter[0].minPrice
            : "",
        maxPrice:
          priceArrayForFilter[0].checked === true
            ? priceArrayForFilter[0].maxPrice
            : "",
        id: selectedThemeValue?._id,
      };
      dispatch(selectorAllProducts(payload));
    }
    dispatch(setLoader(false));
  };

  useEffect(() => {
    callSelectorAllProductsApi();
  }, [
    priceArrayForFilter,
    categoryArrayForFilter,
    brandArrayForFilter,
    colorArrayForFilter,
    fabricArrayForFilter,
    stockArr,
  ]);

  const onClickProductActionButton = async (type) => {
    dispatch(setLoader(true));
    // if (type === "no") {
    //   const selectorAllProductsDataCopy = cloneDeep(selectorAllProductsData);
    //   selectorAllProductsDataCopy.shift();
    //   dispatch(setSelectorAllProductsState(selectorAllProductsDataCopy));
    // } else {
    const payload = {
      id: selectedThemeValue._id,
      productId: selectorAllProductsData[0]?._id,
      team: authData.team,
      add_to_category: type == "add_to_category" ? true : false,
      type
    };
    const response = await dispatch(selectorAddProduct(payload));
    if (response.payload.status === 200 && response.payload.data.status === 1) {
      const selectorAllProductsDataCopy = cloneDeep(selectorAllProductsData);
      selectorAllProductsDataCopy.shift();
      dispatch(setSelectorAllProductsState(selectorAllProductsDataCopy));
    }
    // }
    dispatch(setLoader(false));
  };

  const onClickProfile = () => {
    navigate("/work-hours");
  };

  const onClickThemeItem = (item) => {
    const allThemeArrayValueCopy = cloneDeep(allThemeArrayValue);
    const selectedThemeValueIndex = allThemeArrayValueCopy.findIndex(
      (x) => x._id === selectedThemeValue._id
    );
    const itemIndex = allThemeArrayValue.findIndex((x) => x._id === item._id);
    allThemeArrayValueCopy[selectedThemeValueIndex].selected = false;
    allThemeArrayValueCopy[itemIndex].selected = true;
    setSelectedThemeValue(allThemeArrayValueCopy[itemIndex]);
    setAllThemeArrayValue(allThemeArrayValueCopy);
  };

  return (
    <div className="selector-home-main-container">
      <Header userName={authData?.firstName} onClickProfile={onClickProfile} />
      <div className="selector-home-main-inside-container">
        <div className="selector-home-double-button-container">
          <div className="selector-home-double-button-main-container">
            <Button
              onClick={() => setGenderConfigValue("male")}
              text="Men"
              className={
                genderConfigValue === "male"
                  ? "selector-home-double-button-selected-style"
                  : "selector-home-double-button-unselected-style"
              }
            />
            <Button
              onClick={() => setGenderConfigValue("female")}
              text="Women"
              className={
                genderConfigValue === "female"
                  ? "selector-home-double-button-selected-style"
                  : "selector-home-double-button-unselected-style"
              }
            />
          </div>
        </div>

        <div className="selector-home-horizontal-list-style">
          {allThemeArrayValue.map((item, index) => (
            <ItemBox
              item={item}
              key={index}
              onClick={() => {
                onClickThemeItem(item);
              }}
              selected={item.selected === true ? true : false}
            />
          ))}
        </div>
        <div className="selector-home-bottom-container-style">
          <div className="col-3">
            <div className="d-inline-flex mb-3">
              <Text className="fs-16 fc-eclipse" value={<>Items in&nbsp;</>} />
              <Text
                className="fs-16 fw-b fc-eclipse"
                value={selectedThemeValue?.title}
              />
            </div>

            <Rules content={selectedThemeValue?.rules} />
          </div>
          <div className="col-6 pt-5">
            <ProductCarousel
              isEmpty={selectorAllProductsData.length > 0 ? false : true}
              item={
                selectorAllProductsData.length > 0
                  ? selectorAllProductsData?.[0]?.images
                  : []
              }
            />
            <div className="selector-home-bottom-product-carousel-text-container-style">
              <Text
                className="fs-14 fw-b fc-nero"
                value="Move the item to this theme?"
              />
            </div>
            <div className="selector-home-bottom-product-carousel-container-style">
              <Button
                disabled={selectorAllProductsData.length > 0 ? false : true}
                text="Yes"
                onClick={() => onClickProductActionButton("yes")}
                className="selector-home-bottom-product-carousel-yes-button-style"
              />
              <Button
                disabled={selectorAllProductsData.length > 0 ? false : true}
                text="No"
                onClick={() => onClickProductActionButton("no")}
                className="selector-home-bottom-product-carousel-no-button-style"
              />
              {
                selectorAllProductsData.length > 0 && selectorAllProductsData[0].status != "approved" &&
                <Button
                  disabled={selectorAllProductsData.length > 0 ? false : true}
                  text="Reject from all"
                  onClick={() => onClickProductActionButton("reject")}
                  className="selector-home-bottom-product-carousel-reject-button-style"
                />
              }
              {
                selectorAllProductsData.length > 0 && selectorAllProductsData[0].status != "approved" &&
                <Button
                  disabled={selectorAllProductsData.length > 0 ? false : true}
                  text="Add to Category"
                  onClick={() => onClickProductActionButton("add_to_category")}
                  className="selector-home-bottom-product-carousel-reject-button-style"
                />
              }
            </div>
          </div>
          <div className="col-3">
            <Filter
              priceArray={priceArrayForFilter}
              categoryArray={categoryArrayForFilter}
              brandArray={brandArrayForFilter}
              colorArray={colorArrayForFilter}
              fabricArray={fabricArrayForFilter}
              stockArray={stockArr}
              onClickCheckBox={(item, type) =>
                onClickFilterCheckBox(item, type)
              }
              onClickClearAll={onClickClearAll}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectorHome;
