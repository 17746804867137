import { store } from "../store";

export const verifyEmail = (text) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(text);
};

export const verifyPassword = (text) => {
  const regex = /.{8,}/g; // Check Minimum 8 Digit.
  return regex.test(text);
};

export const getRandomNumber = () => {
 return Math.floor((Math.random() * 1000000000000) + 1);
};

export const isLoggedIn = () => {
  const { token } = store.getState().auth;
  return token ? true : false;
 };


