import Button from "../../../components/button";
import Text from "../../../components/text";
import Checkbox from "../../../components/checkbox";
import { useEffect, useState } from "react";
import "../../../constants/styles.scss";
import "./styles.scss";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const Brand = (props) => {
  const [brands, setBrands] = useState([]);
  const [labelsArr, setLabelsArr] = useState([
    {
      name: "Hyuzter",
      checked: false,
    },
    {
      name: "GramGali",
      checked: false,
    },
    {
      name: "UPfashion",
      checked: false,
    },
    {
      name: "Khem Design",
      checked: false,
    },
    {
      name: "Priya Wear",
      checked: false,
    },
    {
      name: "Aajee",
      checked: false,
    }
  ]);
  const [alpha, setAlpha] = useState([
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ]);
  useEffect(() => {
    let arr = [];
    alpha.map((item) => {
      const arrChar = props.brandArray.filter((b) =>
        b.BrandName.toLowerCase().startsWith(item.toLowerCase())
      );
      if (arrChar.length) {
        arr.push({
          key: item,
          arr: arrChar,
        });
      }
    });
    setBrands(arr);
  }, [props.brandArray]);

  return (
    <div className="">
      {/* Brands */}
      <div className="selector-home-product-filter-boxes-container">
        <Container>
          <Row>
            <Col xs={9} md={9} className="main-brand">
              <Row>
                {brands.map((item) => (
                  <>
                    <Col xs={4} md={4}>
                      <div className="selector-home-product-filter-heading-container">
                        <Text className="fs-14 fw-b fc-nero" value={item.key} />
                      </div>
                      {item.arr.map((brand, index) => (
                        <div
                          key={index + item.key}
                          className="selector-home-product-filter-input-text-container"
                        >
                          <Checkbox
                            checked={brand.checked}
                            onClick={() =>
                              props.onClickCheckBox(brand, "brand")
                            }
                          />
                          <Text
                            className="fs-12 fc-nero mx-2"
                            value={brand.BrandName}
                          />
                        </div>
                      ))}
                    </Col>
                  </>
                ))}
              </Row>
            </Col>
            <Col xs={3} md={3}>
              <div className="selector-home-product-filter-heading-container">
                <Text className="fs-14 fw-b fc-nero" value="Designer Labels" />
              </div>
              {labelsArr.map((labels, index) => (
                <div
                  key={index}
                  className="selector-home-product-filter-input-text-container"
                >
                  <Checkbox
                    checked={labels.checked}
                    onClick={() => {
                      labels.checked = !labels.checked;
                      labelsArr[index] = labels;
                      setLabelsArr([
                        ...labelsArr,
                      ])
                    }}
                  />
                  <Text
                    className="fs-12 fc-nero mx-2"
                    value={labels.name}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Brand;
