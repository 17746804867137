import Colors from "../../constants/colors";

const CalenderIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.33999 2.60059H14.3396C14.7943 2.60059 15.2304 2.76917 15.5519 3.06926C15.8734 3.36935 16.0541 3.77635 16.0541 4.20074V15.4C16.0541 15.8244 15.8734 16.2314 15.5519 16.5315C15.2304 16.8316 14.7943 17.0001 14.3396 17.0001H2.33999C1.88527 17.0001 1.44918 16.8316 1.12765 16.5315C0.806123 16.2314 0.625488 15.8244 0.625488 15.4V4.20074C0.625488 3.77635 0.806123 3.36935 1.12765 3.06926C1.44918 2.76917 1.88527 2.60059 2.33999 2.60059V2.60059Z"
        stroke={color === Colors.black ? Colors.black : color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7666 1V4.2003"
        stroke={color === Colors.black ? Colors.black : color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.91113 1V4.2003"
        stroke={color === Colors.black ? Colors.black : color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.625488 7.40039H16.0541"
        stroke={color === Colors.black ? Colors.black : color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalenderIcon;
