import { useState, useEffect } from "react";
import Text from "../../../components/text";
import ErrorIcon from "../../../assets/icons/error";
import SuccessIcon from "../../../assets/icons/success";
import ExclamationIcon from "../../../assets/icons/exclamation";
import ThreeDotIcon from "../../../assets/icons/threeDot";
import Colors from "../../../constants/colors";
import "./styles.scss";
import "../../../constants/styles.scss";

const NotificationBox = ({ type = "success", message, time }) => {
  const [className, setClassName] = useState(
    "notification-box-main-container notification-box-success-style"
  );

  useEffect(() => {
    getStyles();
  });

  const getStyles = () => {
    switch (type) {
      case "success":
        setClassName(
          "notification-box-main-container notification-box-success-style"
        );
        break;
      case "error":
        setClassName(
          "notification-box-main-container notification-box-error-style"
        );
        break;
      case "warning":
        setClassName(
          "notification-box-main-container notification-box-warning-style"
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className={className}>
      <div className="notification-box-left-side-container">
        {(type === "success" && <SuccessIcon color={Colors.shamrock} />) ||
          (type === "error" && <ErrorIcon color={Colors.coral} />) ||
          (type === "warning" && <ExclamationIcon color={Colors.saffron} />)}

        <Text
          className={`notification-box-status-text-style fs-12 fw-b ${
            (type === "success" && "fc-shamrock") ||
            (type === "error" && "fc-coral") ||
            (type === "warning" && "fc-saffron")
          }`}
          value={
            (type === "success" && "Good Job!") ||
            (type === "error" && "Oops!") ||
            (type === "warning" && "Warning!")
          }
        />
        <Text className="fc-nero fs-12" value={message} />
      </div>

      <div className="notification-box-right-side-container">
        <Text className="fs-10 fc-grey" value={time} />

        <button className="notification-box-menu-button-style">
          <ThreeDotIcon color={Colors.grey} />
        </button>
      </div>
    </div>
  );
};

export default NotificationBox;
