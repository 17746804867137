import { useState, useEffect } from "react";
import Button from "../../../components/button";
import Text from "../../../components/text";
import ArrowLeft from "../../../assets/icons/arrowLeft";
import ArrowRight from "../../../assets/icons/arrowRight";
import Colors from "../../../constants/colors";

import "../../../constants/styles.scss";
import "./styles.scss";

const ProductCarousel = ({ item, isEmpty }) => {
  const [index, setIndex] = useState(0);

  const onClickThumb = (type) => {
    switch (type) {
      case "back":
        if (index > 0) {
          setIndex(index - 1);
        }
        break;
      case "forward":
        if (index < item.length - 1) {
          setIndex(index + 1);
        }
        break;
      default:
        break;
    }
  };

  const isDisable = (type) => {
    switch (type) {
      case "back":
        return index > 0 ? false : true;
      case "forward":
        return index < item.length - 1 ? false : true;
      default:
        break;
    }
  };

  useEffect(() => {
    setIndex(0);
  }, [item]);

  return (
    <div className="selector-home-product-carousel-main-container">
      <div className="selector-home-product-carousel-container">
        <div className="selector-home-product-carousel-thumb-container">
          <button
            className="selector-home-product-carousel-thumb-button-style"
            disabled={isDisable("back")}
            onClick={() => {
              onClickThumb("back");
            }}
          >
            <ArrowLeft color={Colors.mediumSlateBlue} />
          </button>
        </div>
        <div className="selector-home-product-carousel-image-container">
          <div className="selector-home-product-carousel-internal-image-container">
            {isEmpty ? (
              <Text className="fs-14 fw-b fc-nero" value="No Product Found" />
            ) : (
              <img
                src={item[index]}
                alt="dummyImage"
                className="selector-home-product-carousel-image-style"
              />
            )}
          </div>
        </div>
        <div className="selector-home-product-carousel-thumb-container">
          <button
            disabled={isDisable("forward")}
            className="selector-home-product-carousel-thumb-button-style"
            onClick={() => {
              onClickThumb("forward");
            }}
          >
            <ArrowRight color={Colors.mediumSlateBlue} />
          </button>
        </div>
      </div>
      <div className="selector-home-product-carousel-indicator-container">
        {item.map((x, indx) => (
          <div
            key={indx}
            className={
              indx === index
                ? "selector-home-product-carousel-active-indicator-style"
                : "selector-home-product-carousel-indicator-style"
            }
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ProductCarousel;
