import Colors from "../../constants/colors";

const ThreeDotIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3"
      height="15"
      viewBox="0 0 3 15"
      {...props}
    >
      <g
        id="Group_48890"
        data-name="Group 48890"
        transform="translate(-868 -209)"
      >
        <circle
          id="Ellipse_3402"
          data-name="Ellipse 3402"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(868 209)"
          fill={color === Colors.black ? Colors.black : color}
        />
        <circle
          id="Ellipse_3403"
          data-name="Ellipse 3403"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(868 215)"
          fill={color === Colors.black ? Colors.black : color}
        />
        <circle
          id="Ellipse_3404"
          data-name="Ellipse 3404"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(868 221)"
          fill={color === Colors.black ? Colors.black : color}
        />
      </g>
    </svg>
  );
};

export default ThreeDotIcon;
