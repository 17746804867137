import ArrowLeftIcon from "../../../assets/icons/arrowLeft";
import Text from "../../../components/text";
import ProfileUser from "../../../assets/images/profileUser.png";
import "../../../constants/styles.scss";
import "./styles.scss";

const Header = ({ userName, onClickHomeArrow }) => {
  return (
    <div className="dashboard-header-container">
      <div className="dashboard-header-user-profile-container">
        <div onClick={onClickHomeArrow} className="dashboard-header-user-profile-text-container ms-0 cursor-pointer">
          <ArrowLeftIcon height={10} />
          <Text className="fs-12 fw-b fc-nero ms-1" value="Home" />
        </div>
      </div>
      <div className="dashboard-header-user-profile-container">
        <img src={ProfileUser} alt="profile-img" height={32} width={32} />
        <div className="dashboard-header-user-profile-text-container">
          <Text
            className="fs-12 fw-b fc-nero"
            value={<>Hi {userName},&nbsp;</>}
          />
          <Text className="fs-12 fc-nero" value="Welcome Back" />
        </div>
      </div>
    </div>
  );
};

export default Header;
