import Colors from "../../constants/colors";

const CheckIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.452"
      height="5.778"
      viewBox="0 0 7.452 5.778"
      {...props}
    >
      <path
        id="Path_120544"
        data-name="Path 120544"
        d="M10537.4-635.04l1.988,2.039,3.344-3.628"
        transform="translate(-10536.34 637.689)"
        fill="none"
        stroke={color === Colors.black ? Colors.black : color}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default CheckIcon;
