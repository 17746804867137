import Colors from "../../constants/colors";

const CategoryIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      id="noun-category-2659310"
      xmlns="http://www.w3.org/2000/svg"
      width="16.911"
      height="16.576"
      viewBox="0 0 16.911 16.576"
      {...props}
    >
      <path
        id="Path_112583"
        data-name="Path 112583"
        d="M210.888,78.05h9.106a.634.634,0,0,0,.514-1.015l-4.553-5.854a.65.65,0,0,0-1.028,0l-4.553,5.854a.634.634,0,0,0,.514,1.015Zm4.553-5.444,3.252,4.143h-6.5Z"
        transform="translate(-206.985 -70.93)"
        fill={color === Colors.black ? Colors.black : color}
      />
      <path
        id="Path_112584"
        data-name="Path 112584"
        d="M129.655,280h-6.5a.65.65,0,0,0-.65.65v6.5a.65.65,0,0,0,.65.65h6.5a.65.65,0,0,0,.65-.65v-6.5a.65.65,0,0,0-.65-.65Zm-.65,6.5h-5.2v-5.2H129Z"
        transform="translate(-122.5 -271.229)"
        fill={color === Colors.black ? Colors.black : color}
      />
      <path
        id="Path_112585"
        data-name="Path 112585"
        d="M371.4,280a3.9,3.9,0,1,0,2.76,1.143A3.9,3.9,0,0,0,371.4,280Zm0,6.5a2.6,2.6,0,1,1,1.84-.762,2.6,2.6,0,0,1-1.84.762Z"
        transform="translate(-358.394 -271.229)"
        fill={color === Colors.black ? Colors.black : color}
      />
    </svg>
  );
};

export default CategoryIcon;
