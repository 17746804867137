import { createAsyncThunk } from "@reduxjs/toolkit";

import authApi from "../../../apis/authApi";
import authApiEndpoint from "../../../apis/authApi/config";

export const loginAddedUsers = createAsyncThunk(
  `${authApiEndpoint.loginAddedUsers}Post`,
  async (payload) => {
    const { status, data } = await authApi.postLoginAddedUsers(payload);
    return { status, data };
  }
);

export const forgotpasswordLink = createAsyncThunk(
  `${authApiEndpoint.loginAddedUsers}Post`,
  async (payload) => {
    const { status, data } = await authApi.postForgotPasswordLink(payload);
    return { status, data };
  }
);

// export const logoutUser = createAsyncThunk(
//   `${authApiEndpoint.logoutUser}Get`,
//   async () => {
//     const { status, data } = await authApi.logoutUser();
//     return { status, data };
//   }
// );
