import { Provider } from "react-redux";
import { useState, useEffect, useContext } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/index";
import ToastContainer from "./components/toast";
import Loader from "./components/loader";
import MainRoutes from "./routes";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ToastContainer>
          <Loader/>
          <MainRoutes />
        </ToastContainer>
      </PersistGate>
    </Provider>
  );
};

export default App;
