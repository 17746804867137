import Colors from "../../constants/colors";

const CartIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color === Colors.black ? Colors.black : color}
      {...props}
    >
      <g
        id="Group_49326"
        data-name="Group 49326"
        transform="translate(-54 -142)"
      >
        <path
          id="Path_112619"
          data-name="Path 112619"
          d="M93,56a.6.6,0,0,0,0,1.2h2.4c1,2.467,1.977,4.938,2.965,7.409L97.457,66.8a.6.6,0,0,0,.558.834h10.029a.6.6,0,1,0,0-1.2H98.918l.52-1.241,9.659-.771a.619.619,0,0,0,.539-.464l1.2-5.215a.63.63,0,0,0-.589-.733H97.018l-.652-1.63A.617.617,0,0,0,95.809,56Zm4.5,3.209h11.991l-.934,4.049-9.145.727Zm2.52,8.825a2.006,2.006,0,1,0,2.006,2.006A2.015,2.015,0,0,0,100.021,68.035Zm6.017,0a2.006,2.006,0,1,0,2.006,2.006A2.015,2.015,0,0,0,106.038,68.035Zm-6.017,1.2a.8.8,0,1,1-.8.8A.793.793,0,0,1,100.021,69.238Zm6.017,0a.8.8,0,1,1-.8.8A.793.793,0,0,1,106.038,69.238Z"
          transform="translate(-35.398 90)"
        />
        <rect
          id="Rectangle_17849"
          data-name="Rectangle 17849"
          width="24"
          height="24"
          transform="translate(54 142)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default CartIcon;
