import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Text from "../../components/text";
import Dropdown from "../../components/dropdown";
import CalendarBox from "./calendarBox";
import workHoursApi from "../../apis/workHours";
import { setLoader } from "../../store/reducer/ui";
import moment from "moment";
import "./styles.scss";
import "../../constants/styles.scss";

const WorkHours = () => {
  const authData = useSelector((state) => state.auth);

  const [yearDropDownOption, setYearDropDownOption] = useState([]);
  const [yearDropDownSelectedItem, setYearDropDownSelectedItem] = useState();

  const [monthDropDownOption, setMonthDropDownOption] = useState([]);
  const [monthDropDownSelectedItem, setMonthDropDownSelectedItem] = useState();

  const startYear = moment(authData.createdAt, "YYYY-MM-DD").format("YYYY");
  const endYear = moment().format("YYYY");

  const [apiResData, setApiResData] = useState([]);
  const [calenderData, setCalenderData] = useState([]);

  const list = () => {
    if (monthDropDownSelectedItem && yearDropDownSelectedItem) {
      let arr = [];

      const monthCount = moment(
        `${yearDropDownSelectedItem}-${moment(
          monthDropDownSelectedItem,
          "MMMM"
        ).format("MM")}`,
        "YYYY-MM"
      ).daysInMonth();

      for (let i = 1; i <= monthCount; i++) {
        const date = moment(
          `${moment(monthDropDownSelectedItem, "MMMM").format(
            "MM"
          )}-${i}-${yearDropDownSelectedItem}`
        ).format("MM-DD-YYYY");
        const index = apiResData.findIndex((element) => element._id === date);
        let obj;
        if (index !== -1) {
          obj = {
            empty: false,
            date,
            day: moment(
              `${moment(monthDropDownSelectedItem, "MMMM").format(
                "MM"
              )}-${i}-${yearDropDownSelectedItem}`
            ).day(),
            order_selection: apiResData[index].order_selection,
            product_selection: apiResData[index].product_selection,
          };
        } else {
          obj = {
            empty: false,
            date,
            day: moment(
              `${moment(monthDropDownSelectedItem, "MMMM").format(
                "MM"
              )}-${i}-${yearDropDownSelectedItem}`
            ).day(),
            order_selection: 0,
            product_selection: 0,
          };
        }
        arr.push(obj);
      }
      const loopTimes = arr[0].day;
      for (let i = 0; i < loopTimes; i++) {
        arr.unshift({ empty: true, day: loopTimes - 1 - i });
      }
      setCalenderData(arr);
    }
  };

  useEffect(() => {
    list();
  }, [apiResData]);

  const callSelectorPerformanceCalendarApi = async () => {
    setLoader(true);
    if (monthDropDownSelectedItem && yearDropDownSelectedItem) {
      const monthCount = moment(
        `${yearDropDownSelectedItem}-${moment(
          monthDropDownSelectedItem,
          "MMMM"
        ).format("MM")}`,
        "YYYY-MM"
      ).daysInMonth();

      const p = {
        startDate: `${moment(monthDropDownSelectedItem, "MMMM").format(
          "MM"
        )}/01/${yearDropDownSelectedItem}`,
        endDate: `${moment(monthDropDownSelectedItem, "MMMM").format(
          "MM"
        )}/${monthCount}/${yearDropDownSelectedItem}`,
      };

      await workHoursApi
        .selectorPerformanceCalendar(p)
        .then((res) => {
          if (res.status === 200 && res.data.status === 1) {
            setApiResData(res.data.data);
          }
        })
        .catch((err) => {});
    }

    setLoader(false);
  };

  useEffect(() => {
    callSelectorPerformanceCalendarApi();
  }, [monthDropDownSelectedItem, yearDropDownSelectedItem]);

  const createMonthArray = () => {
    const startMonth =
      yearDropDownSelectedItem === startYear
        ? moment(authData.createdAt, "YYYY-MM-DD").format("MM")
        : "01";

    const endMonth =
      yearDropDownSelectedItem === endYear ? moment().format("MM") : "12";

    const monthDiff = moment(endMonth).diff(startMonth, "months", false);

    let monthArr = [];

    for (let i = 0; i <= monthDiff; i++) {
      let num = moment(startMonth, "MM").add(i, "months").format("MMMM");
      monthArr.push(num);
    }

    setMonthDropDownSelectedItem(monthArr[0]);
    setMonthDropDownOption(monthArr);
  };

  useEffect(() => {
    createMonthArray();
  }, [yearDropDownSelectedItem]);

  const createDateArray = () => {
    const yearDiff = moment(endYear).diff(startYear, "years", false);

    let yearArr = [];

    for (let i = 0; i <= yearDiff; i++) {
      let num = moment(startYear, "YYYY").add(i, "years").format("YYYY");
      yearArr.push(num);
    }

    setYearDropDownSelectedItem(yearArr[0]);
    setYearDropDownOption(yearArr);
  };

  useEffect(() => {
    createDateArray();
  }, []);

  return (
    <div className="container-fluid dashboard-pages-reusable-style">
      <div className="work-hours-title-container-style">
        <Text
          className="dashboard-pages-reusable-title-style"
          value="Work Hours"
        />
        <div className="d-inline-flex">
          <div className="me-2">
          <Dropdown
            selected={monthDropDownSelectedItem}
            options={monthDropDownOption}
            onClickMenuItem={(item) => setMonthDropDownSelectedItem(item)}
          />
          </div>
          
          <Dropdown
            selected={yearDropDownSelectedItem}
            options={yearDropDownOption}
            onClickMenuItem={(item) => setYearDropDownSelectedItem(item)}
          />
        </div>
      </div>

      <div>
        <div className="work-hours-days-name-main-container">
          <div className="work-hours-days-box-style">
            <Text value="Sun" className="fs-14 fw-b fc-nero" />
          </div>
          <div className="work-hours-days-box-style">
            <Text value="Mon" className="fs-14 fw-b fc-nero" />
          </div>
          <div className="work-hours-days-box-style">
            <Text value="Tues" className="fs-14 fw-b fc-nero" />
          </div>
          <div className="work-hours-days-box-style">
            <Text value="Wed" className="fs-14 fw-b fc-nero" />
          </div>
          <div className="work-hours-days-box-style">
            <Text value="Thur" className="fs-14 fw-b fc-nero" />
          </div>
          <div className="work-hours-days-box-style">
            <Text value="Fri" className="fs-14 fw-b fc-nero" />
          </div>
          <div className="work-hours-days-box-style">
            <Text value="Sat" className="fs-14 fw-b fc-nero" />
          </div>
        </div>
        <div className="work-hours-days-list-main-container">
          {calenderData.map((item) => (
            <CalendarBox item={item} />
          ))}
        </div>
        <div className="work-hours-days-info-main-container">
          <div className="work-hours-days-info-text-container">
            <div className="work-hours-days-dot-saffron-style"></div>
            <Text value="Order Selection" className="fs-16 fc-nero" />
          </div>
          <div className="work-hours-days-info-text-container ms-3">
            <div className="work-hours-days-dot-medium-slate-blue-style"></div>
            <Text value="Product Selection" className="fs-16 fc-nero" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkHours;
